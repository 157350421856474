import React from "react";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import CustomSelect from "../../common/CustomSelect/CustomSelectDoc";
import useLoader from "../../../hooks/useLoader";
// import { caseDocumentType } from "../../../helpers/constants";
import styled from "styled-components";

const DocChangeType = ({ rowData, ownerType, agentRole, MTRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  let docId = rowData?.id;
  let caseId = rowData?.entityId;

  const changeDocType = async (value) => {
    try {
      setLoader({ state: true, message: "Change Document..." });
      const payload = {
        uploadType: value,
      };
      const res = await CaseService.changeDocType(payload, caseId, docId);
      if (res) {
        enqueueSnackbar("Document Type Changed Successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
    }
  };

  const caseDocumentType = [
    { label: "Select Document Type", value: "", disabled: true },
    {
      label: "Request for Arbitration",
      value: "Request for Arbitration",
      color: "#0000FF",
    },
    {
      label: "Documents filed along with the Request for Arbitration",
      value: "Documents filed along with the Request for Arbitration",
      color: "#FF1493",
    },
    {
      label: "Statement of Claim",
      value: "Statement of Claim",
      color: "#4B0082",
    },
    {
      label: "Documents filed along with the Statement of Claim",
      value: "Documents filed along with the Statement of Claim",
      color: "#8B008B",
    },
    {
      label: "Application (to be filed as per IIAC Regulations)",
      value: "Application (to be filed as per IIAC Regulations)",
      color: "#006400",
    },
    {
      label: "Replies/ Counter Affidavits /Rejoinder Affidavits etc",
      value: "Replies/ Counter Affidavits /Rejoinder Affidavits etc",
      color: "#FFBF00",
    },
    {
      label: "Claimant Evidence Statement and any document filed along with it",
      value: "Claimant Evidence Statement and any document filed along with it",
      color: "#FF7F50",
    },
    {
      label: "Statement/Affidavit of Admission and Denial of Documents",
      value: "Statement/Affidavit of Admission and Denial of Documents",
      color: "#DE3163",
    },
    {
      label: "Response to the Request for Arbitration",
      value: "Response to the Request for Arbitration",
      color: "#ff3c3c",
    },
    {
      label:
        "Documents filed along with the Response to the Request for Arbitration",
      value:
        "Documents filed along with the Response to the Request for Arbitration",
      color: "#6495ED",
    },
    {
      label: "Statement of Defence /Counter Claim",
      value: "Statement of Defence /Counter Claim",
      color: "#40E0D0",
    },
    {
      label: "Documents filed along with the Defence /Counter Claim",
      value: "Documents filed along with the Defence /Counter Claim",
      color: "#FF00FF",
    },
    {
      label:
        "Respondent Evidence Statement and any document filed along with it",
      value:
        "Respondent Evidence Statement and any document filed along with it",
      color: "#800080",
    },
    {
      label: "Any other document",
      value: "Any other document",
      color: "#00838c",
    },
    {
      label:
        rowData?.url?.uploadType === "Request for Arbitration"
          ? null
          : rowData?.url?.uploadType ===
            "Documents filed along with the Request for Arbitration"
          ? null
          : rowData?.url?.uploadType === "Statement of Claim"
          ? null
          : rowData?.url?.uploadType ===
            "Documents filed along with the Statement of Claim"
          ? null
          : rowData?.url?.uploadType ===
            "Application (to be filed as per IIAC Regulations)"
          ? null
          : rowData?.url?.uploadType ===
            "Replies/ Counter Affidavits /Rejoinder Affidavits etc"
          ? null
          : rowData?.url?.uploadType ===
            "Claimant Evidence Statement and any document filed along with it"
          ? null
          : rowData?.url?.uploadType ===
            "Statement/Affidavit of Admission and Denial of Documents"
          ? null
          : rowData?.url?.uploadType ===
            "Response to the Request for Arbitration"
          ? null
          : rowData?.url?.uploadType ===
            "Documents filed along with the Response to the Request for Arbitration"
          ? null
          : rowData?.url?.uploadType === "Statement of Defence /Counter Claim"
          ? null
          : rowData?.url?.uploadType ===
            "Documents filed along with the Defence /Counter Claim"
          ? null
          : rowData?.url?.uploadType ===
            "Respondent Evidence Statement and any document filed along with it"
          ? null
          : rowData?.url?.uploadType === "Any other document"
          ? null
          : `${rowData?.url?.uploadType} (Others)`,
      value: rowData?.url?.uploadType,
      disabled: true,
      color: "#5A5A5A",
    },
  ];

  return (
    <Container className="input-select">
      <CustomSelect
        menuItemValues={caseDocumentType}
        name={rowData?.url?.uploadType}
        value={rowData?.url?.uploadType}
        disabled={ownerType === agentRole ? false : true}
        onChange={(e) => changeDocType(e.target.value)}
      />
    </Container>
  );
};

export default DocChangeType;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;
