import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import useLoader from "../../../hooks/useLoader";
import AWSService from "../../../services/AWSService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import {
  numberFormat,
  getErrorMessage,
  parseTimeStamp,
} from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import DescriptionModal from "../DescriptionModal";
import AgentListModal from "../AgentListModal/CaseAgentList";
import DocumentUploadModal from "../MultipleDocumentUploadModal";
import AlertDialog from "../../common/Alert";
import PaymentDrawer from "../../internal/PaymentDrawer";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionHeading } from "../../internal/DrawerTable";
import ViewNotesModal from "../../internal/ViewNotesModal";
import ClaimItemsListModal from "../ClaimItemsListModal/index";
import CounterClaimModal from "../ClaimItemsListModal/CounterClaim";

const useStyles = makeStyles((themes) => ({
  root: {
    boxShadow: "none",

    "&:last-child": {
      border: `solid 0.5px #e1e3ee`,
    },
    [themes.breakpoints.up(640)]: {
      border: `solid 0.5px #e1e3ee`,
    },
    margin: `0 !important`,
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 !important`,
  },
}));

const Index = ({
  caseDetails,
  setState,
  id,
  MTRef,
  isActionsDisabled = false,
  partyList,
  isViewAction = false,
  setFields,
  docUploadModal,
  setDocUploadModal,
  setTriggerCaseCall,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const fileRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [file] = useState([]);
  const [modal, setModal] = useState();
  const [url, setUrl] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [opendrawer, setOpenDrawer] = useState(false);
  const [viewNotes, setViewNotes] = useState(false);
  const [opendrawerClaim, setOpenDrawerClaim] = useState(false);
  const [data, setData] = useState([]);
  const [claimModal, setClaimModal] = useState(false);

  // async function s3Upload(file) {
  //   if (file) {
  //     try {
  //       setLoader({ state: true, message: `Uploading file ${file.name} ...` });
  //       const payload = {
  //         key: file.name
  //       };
  //       const s3Response = await AWSService.getS3URL(payload);
  //       if (s3Response.url) {
  //         const res = await AWSService.uploadToS3(s3Response.url, file);
  //         setUrl(res);
  //       }
  //     } catch (error) {
  //       const message = getErrorMessage(error);
  //       enqueueSnackbar(message, { variant: "error" });
  //     } finally {
  //       setLoader({ state: false });
  //     }
  //   }
  // }

  async function s3Upload(file) {
    if (file) {
      let len = file.length;
      let fileName = [];
      for (let i = 0; i < len; i++) {
        fileName.push(file[i]?.name);
      }
      try {
        setLoader({ state: true, message: `Uploading file...` });
        const payload = {
          key: fileName,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const s3url = [];
          for (let i = 0; i < len; i++) {
            const res = await AWSService.uploadToS3(
              s3Response?.url[i],
              file[i],
            );
            s3url.push(res);
          }
          setUrl(s3url);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  /** Function to get the negotiation round Details */
  async function addDocumentForCase(data) {
    try {
      const documentDetails = data;
      setLoader({ state: true, message: `Uploading document` });
      const upload_response = await CaseService.caseAddDocument(
        { documentDetails },
        id,
      );
      if (upload_response?.message) {
        enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        if (MTRef?.current?.onSearchChange) {
          MTRef.current.onSearchChange("");
        }
      }
      // setUrl("");
      setData([]);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenAlert(false);
      setDocUploadModal(false);
      setAlertData({});
    }
  }

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ content: classes.content }}
        >
          <Header>
            <Heading>{caseDetails.title}</Heading>
            <Actions>
              {caseDetails?.resolutionKind === "arbitration" && (
                <HyperLink onClick={() => setFields(true)}>
                  Case Details
                </HyperLink>
              )}
              <HyperLink onClick={() => setViewNotes(true)}>
                {"Scratch Pad"}
              </HyperLink>
              {caseDetails?.respondentStatus ===
                "pendingRespondentRegistrationFee" &&
              caseDetails?.agentRole === "respondent" ? (
                <HyperLink onClick={() => navigate("/dashboard/cart")}>
                  Pay Registration fee
                </HyperLink>
              ) : (
                <HyperLink onClick={() => setOpenPaymentModal(true)}>
                  Payments
                </HyperLink>
              )}
              {partyList?.length ? (
                <HyperLink
                  style={{ display: "table" }}
                  onClick={() => setOpenDrawer((a) => ({ ...a, state: true }))}
                >
                  Agent Details
                </HyperLink>
              ) : null}
              {/* <HyperLink
                disabled={
                  isActionsDisabled ||
                  (caseDetails?.respondentStatus ===
                    "pendingRespondentRegistrationFee" &&
                    caseDetails?.agentRole === "respondent")
                }
                onClick={() =>
                  !(
                    isActionsDisabled ||
                    (caseDetails?.respondentStatus ===
                      "pendingRespondentRegistrationFee" &&
                      caseDetails?.agentRole === "respondent")
                  ) && setDocUploadModal(true)
                }
              >
                Add Document
              </HyperLink> */}
              <HyperLink
                disabled={isViewAction}
                onClick={(e) => !isViewAction && setState(true)}
              >
                Case Documents
              </HyperLink>
              <DropDown>
                <Icon
                  onClick={() => setExpanded((e) => !e)}
                  src={require("../../../assets/images/dropdownIcon.svg")}
                  alt="dropdown"
                  expanded={expanded}
                />
              </DropDown>
            </Actions>
          </Header>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <TableWrapper>
            <LabelValueContainer>
              <Label>Case ID</Label>
              <Value>{caseDetails.id}</Value>
            </LabelValueContainer>

            <LabelValueContainer>
              <Label>Claimant</Label>
              <Value>{caseDetails?.claimantParty?.name}</Value>
            </LabelValueContainer>

            <LabelValueContainer>
              <Label>Respondent</Label>
              <Value>
                {caseDetails?.respondentParty?.name ||
                caseDetails.respondentName ? (
                  <>
                    {caseDetails?.respondentParty?.name
                      ? caseDetails?.respondentParty?.name
                      : caseDetails.respondentName}
                  </>
                ) : (
                  <>
                    {caseDetails?.respondentParties?.length
                      ? caseDetails?.respondentParties[0]?.name
                      : ""}
                  </>
                )}
                {partyList?.filter((el) => el.partyRole === "respondent")
                  ?.length -
                  1 >
                0 ? (
                  <HyperLink
                    style={{ marginLeft: 8, display: "inline" }}
                    onClick={() =>
                      setOpenDrawer((a) => ({ ...a, state: true }))
                    }
                  >
                    +{" "}
                    {partyList?.filter((el) => el.partyRole === "respondent")
                      ?.length - 1}
                  </HyperLink>
                ) : null}
              </Value>
            </LabelValueContainer>

            <LabelValueContainer>
              <Label>Monetary Claim Value</Label>
              <Value>
                {caseDetails.totalClaimValue
                  ? numberFormat(
                      parseFloat(caseDetails.totalClaimValue).toFixed(2),
                      caseDetails.currencyUnit,
                    )
                  : "Non Monetary"}
                {caseDetails?.resolutionKind === "mediation" &&
                caseDetails?.caseItems?.length ? (
                  <HyperLink
                    style={{ marginLeft: 8, display: "inline" }}
                    onClick={() =>
                      setOpenDrawerClaim((a) => ({ ...a, state: true }))
                    }
                  >
                    +{" "}
                  </HyperLink>
                ) : (
                  ""
                )}
                {caseDetails?.resolutionKind === "arbitration" &&
                caseDetails?.counterClaimValue !== 0 ? (
                  <HyperLink
                    style={{ marginLeft: 8, display: "inline" }}
                    onClick={() => setClaimModal(true)}
                  >
                    +{" "}
                  </HyperLink>
                ) : null}
              </Value>
            </LabelValueContainer>

            <LabelValueContainer>
              <Label>Filed on</Label>
              <Value>
                {" "}
                {caseDetails.created_at &&
                  moment(parseTimeStamp(caseDetails.created_at)).format(
                    "DD/MM/YYYY",
                  )}
              </Value>
            </LabelValueContainer>

            <LabelValueContainer>
              <Label>Last Updated</Label>
              <Value>
                {caseDetails.updated_at &&
                  moment(parseTimeStamp(caseDetails.updated_at)).format(
                    "DD/MM/YYYY",
                  )}
              </Value>
            </LabelValueContainer>
            {caseDetails?.nextHearingDate?.date?.length ? (
              <LabelValueContainer>
                <Label>Next Hearing Date</Label>
                <Value>{caseDetails?.nextHearingDate?.time}</Value>
              </LabelValueContainer>
            ) : (
              ""
            )}
          </TableWrapper>

          <TableWrapper>
            <LabelValueContainer style={{ width: "100%", marginBottom: 0 }}>
              <Value>
                <Accordion className={classes.root}>
                  <AccordionSummary
                    style={{ marginRight: "20px" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <AccordionHeading
                      style={{ display: "flex", paddingLeft: "20px" }}
                    >
                      Case Description
                      {caseDetails?.caseTopic?.length > 0 && (
                        <span
                          style={{
                            marginLeft: "12px",
                            color: "rgba(246,180,54, 18)",
                          }}
                        >
                          - Keywords - ({caseDetails.caseTopic.join(", ")})
                        </span>
                      )}
                    </AccordionHeading>
                  </AccordionSummary>
                  <CommentContent
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingBottom: "30px",
                      paddingTop: "auto",
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                  >
                    {caseDetails?.description}
                  </CommentContent>
                </Accordion>
              </Value>
            </LabelValueContainer>
          </TableWrapper>

          <Row>
            <Column>
              {caseDetails?.resolutionKind === "negotiation" ? (
                <div>
                  <span className="label">Total Rounds:</span>{" "}
                  {caseDetails?.negotiationRoundLimit}
                </div>
              ) : null}
            </Column>
          </Row>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <AgentListModal
        {...{
          opendrawer,
          setOpenDrawer,
          MTRef,
          partyList,
          displayAuthUrl: false,
          setTriggerCaseCall,
        }}
        caseId={caseDetails.id}
      />
      <ClaimItemsListModal
        {...{
          opendrawerClaim,
          setOpenDrawerClaim,
          MTRef,
          displayAuthUrl: false,
        }}
        caseDetails={caseDetails}
      />
      <CounterClaimModal
        {...{
          claimModal,
          setClaimModal,
          MTRef,
          caseDetails,
        }}
      />
      <ViewNotesModal
        {...{ partyList }}
        disabled={isActionsDisabled}
        modal={viewNotes}
        setModal={setViewNotes}
        type={caseDetails?.agentRole === "claimant" ? "Claimant" : "Respondent"}
        id={id}
      />
      <DescriptionModal
        {...{ modal, setModal }}
        description={caseDetails?.description}
      />
      <DocumentUploadModal
        modal={docUploadModal}
        setModal={setDocUploadModal}
        handleUpload={s3Upload}
        file={file}
        fileRef={fileRef}
        url={url}
        setUrl={setUrl}
        data={data}
        setData={setData}
        resolutionKind={caseDetails?.resolutionKind}
        shareDefault={caseDetails?.resolutionKind === "arbitration"}
        shareDefaultMed={
          caseDetails?.resolutionKind === "mediation" ||
          caseDetails?.resolutionKind === "negotiation"
        }
        handleSuccess={(data) => {
          setOpenAlert(true);
          setAlertData({
            clickSecondarybtn: () => setOpenAlert(false),
            onDialogClose: () => setOpenAlert(false),
            primaryBtnText: "OK",
            secondaryBtnText: "Cancel",
            desc:
              data && data.length === 1
                ? `${
                    data[0]["visibilityType"] === "all"
                      ? "Are you sure you want to share this document with other parties, IIAC Counsel and Neutral(s)?"
                      : data[0]["visibilityType"] === "me"
                      ? "Are you sure you want to keep this document private?"
                      : "Are you sure you want to share this document only with IIAC Counsel and Neutral(s)?"
                  }`
                : "Are you sure you want to share this document?",
            // desc: `Are you sure you want to share this document?`,
            heading: `Alert`,
            clickPrimaryBtn: () => addDocumentForCase(data),
          });
        }}
      />
      <AlertDialog
        isOpen={openAlert}
        {...{ ...alertData }}
        // descriptionTextStyle={{
        //   textAlign: "left"
        // }}
      />
      <PaymentDrawer
        {...{ MTRef, setOpenPaymentModal, openPaymentModal }}
        id={caseDetails?.id}
      />
    </>
  );
};

export default Index;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: default;
  padding: 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 15px 21px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
  }
`;
const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${theme?.breakpoints?.lg_up} {
    flex-wrap: nowrap;
    align-items: center;
    & div {
      margin-left: 35px;
    }
  }
`;
const DropDown = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 17px 0 25px;
  border-radius: 50%;
  background-color: ${COLORS.TABLE_GREY_CELL};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
  @media ${theme?.breakpoints?.lg_up} {
    display: flex;
  }
`;
const TableWrapper = styled.div`
  // border: 2px solid black;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
  width: 100%;
  &:nth-child(2) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0 21px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 6px 0 7px 0;
    padding: 0 21px 0 21px;
    &:nth-child(2) {
      margin-top: 9px;
    }
  }
`;
const LabelValueContainer = styled.div`
  // border: 2px solid black;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 25px;
  &:nth-child(5),
  &:nth-child(6) {
    margin-bottom: 9px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    width: auto;
    margin-bottom: 0 !important;
  }
`;
const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.INPUT_LABEL};
  text-align: left;
  // border: 2px solid black;
`;
const Value = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: #293461;
  @media ${theme?.breakpoints?.lg_up} {
    font-size: 14px;
  }
`;

const Column = styled.div`
  padding: 20px 22px;
  max-width: 50%;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  & div {
    margin-bottom: 10px;
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 14px;
    color: #293461;
  }
  & .label {
    font-size: 10px;
    font-weight: 600;
    color: ${COLORS.INPUT_LABEL};
  }
`;

const Row = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 11px;
  height: 6px;
  object-fit: "contain";
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: 5sec;
`;

const CommentContent = styled.div`
  font-size: 14px;
  word-break: break-word;
  text-align: justify;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  // margin-top: 10px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-right {
    text-align: right;
  }
  // & blockquote {
  //   border-left: 4px solid #ccc;
  //   margin-bottom: 5px;
  //   margin-top: 5px;
  //   padding-bottom: 5px;
  //   padding-top: 5px;
  //   padding-left: 16px;
  //   background-color: #f9f9f9;
  // }
`;

export const HyperLink = styled.div`
  width: 50%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-top: 25px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
  @media ${theme?.breakpoints?.lg_up} {
    margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
    width: auto;
    margin-top: 0;
  }
`;

export const Heading = styled.span`
  margin-bottom: 0;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  flex: 1;
  color: ${COLORS.COLOR_DARK};
  @media ${theme?.breakpoints?.lg_up} {
    font-family: ${theme.fonts.primaryFontExtraBold};
    font-size: 18px;
    margin-left: 21px;
  }
`;
