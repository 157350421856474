import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../../../common/ActionBar";
import {
  PageWrapper,
  StyledLabel,
  LifeCycleContainer,
  Manager,
  Spacing,
  Block,
} from "../styles";
import LifeCycle from "../../../common/LifeCycle";
import CaseDetails from "../../../common/CaseDetails";
import CaseManager from "../common/CaseManager";
import BigBlueButton from "../common/BigBlueButton";
import DrawerTableCaseDocument from "../../../internal/DrawerTable";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseCTA from "../common/CaseCTA";
import AlertDialog from "../../../common/Alert";
import CaseService from "../../../../services/CaseService";
import {
  RESPONTANT_LIFE_CYCLE,
  CLAIMANT_LIFE_CYCLE,
  AWAITING_RESPONDANT,
  CLAIMENT_STR,
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  BOTH_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT,
} from "../../../../helpers/constants";
import { getErrorMessage } from "../../../../helpers/functions";
import { useSnackbar } from "notistack";
import useLoader from "../../../../hooks/useLoader";
import { callSendOffer, callQuitCase } from "../negotiation-helper";
import useCartCount from "../../../../hooks/useCartCount";
import useAlert from "../../../../hooks/useAlert";
import theme from "../../../../assets/theme";
import MediationState from "./MediationState";
import queryString from "query-string";
import useNotification from "../../../../hooks/useNotification";
import { CaseManagerCardData } from "../../../pages/NegotiationPages/styles";
import { PrimaryCTAButton } from "../../../common/Buttons";
import ExtraFieldsDrawer from "../../../internal/DrawerTable/ExtraFieldsDrawer";

function negotiationTypeState({ ...props }) {
  switch (props?.caseDetails?.resolutionKind) {
    case "arbitration":
      return <MediationState {...props} />;
    default:
      return null;
  }
}

export default function AwaitingRespondantPage({ id }) {
  const initialState = ["My Cases"];
  const [breadcrumbs, setBreadcrumbs] = useState(initialState); // This state is to display the bread crum
  const [state, setState] = useState(); // This state is used for caseDocument
  const [open, setOpen] = useState(false); //This state is for open and Close the model
  const [dialogData, setDialogData] = useState({}); // This state is used to set the dialog content
  const [comments, setComments] = useState([]); // This is list of comments
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [triggerCaseCall, setTriggerCaseCall] = useState(true); // This State to trigger the get details call when any update happend in the case
  const [buttonToDisplay, setButtonToDisplay] = useState([]); //This state used to hold the button list based on case life cycle and user Action
  const [showCta, setShowCta] = useState(false); // This state used to show and hide the button based on the user action
  const [partyList, setPartyList] = useState([]);
  const [type, setType] = useState();

  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const formikRef = useRef([]); // This reference holds the formik values
  const { setTriggerCount } = useCartCount(); // This context to trigger the cart count when negotiation moves to payment.
  const MTRef = useRef();
  const { hideAlert } = useAlert();
  const { triggerNotify, setTriggerNotify } = useNotification(); // hooks to refresh the page
  const [statusCheck, setStatusCheck] = useState();
  const resolutionKindData = caseDetails?.resolutionKind;
  const [docUploadModal, setDocUploadModal] = useState();
  const [fields, setFields] = useState(false);

  useEffect(() => {
    if (triggerNotify) {
      setTriggerCaseCall(true);
      setTriggerNotify(false);
    }
  }, [setTriggerNotify, triggerNotify]);

  /**This useEffect is Used to get the case information */
  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        if (res) {
          let params = {
            page: 1,
            perPage: 1000,
          };
          if (res?.resolutionKind !== "negotiation") {
            let stringParams = "";
            if (!_.isEmpty(params)) {
              stringParams = `?${queryString.stringify(params)}`;
            }
            const mediation_res = await CaseService.mediations(
              id,
              stringParams
            ); // This is for getting the party
            if (mediation_res) {
              setComments(mediation_res?.data);
            }
          }

          const partyParam = `?${queryString.stringify(
            params
          )}&sort=partyRole&sortDirection=asc`;
          const party_list_res = await CaseService.getPartyListByCaseId(
            id,
            partyParam
          ); // This is for getting the party details based on the case
          if (party_list_res?.data) {
            setPartyList(party_list_res?.data);
          }

          let case_response = { ...res };
          if (!case_response?.respondentPartyId) {
            case_response.status =
              res?.status === "closedByCaseManager"
                ? AWAITING_RESPONDANT_QUIT
                : res.status === "arbitrationReference" ||
                  "noticeToArbitrate" ||
                  "appointmentOfArbitrator" ||
                  "acceptanceByArbitrator" ||
                  "firstHearingIntimation" ||
                  "filingStatementofClaim" ||
                  "filingofSection17" ||
                  "section17OrderPassed" ||
                  "filingofStatementofDefence" ||
                  "rejoinderfromClaimant" ||
                  "surrejoinderFromRespondent" ||
                  "2ndNoticeMOM" ||
                  "crossExaminationClaimantWitness" ||
                  "crossExaminationRespondentWitness" ||
                  "arguments" ||
                  "reservedForAward"
                ? res.status
                : AWAITING_RESPONDANT;
          } else if (
            case_response?.respondentStatus === "pending" ||
            case_response?.respondentStatus === "declined"
          ) {
            case_response.status = RESPONDANT_ONBOARDED;
          } else if (
            case_response?.status === NEGOTIATION_REACHED &&
            case_response?.subscriptionKind === "adhoc"
          ) {
            case_response.status = BOTH_PAID_NEGOTIATION;
          }
          setCaseDetails(case_response);
          setBreadcrumbs([
            "My Cases",
            _.startCase(case_response?.resolutionKind),
            case_response?.title,
          ]);
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          if (case_response.agentRole === CLAIMENT_STR) {
            setInitialValues(
              JSON.parse(JSON.stringify(CLAIMANT_LIFE_CYCLE)),
              case_response,
              false
            );
          } else {
            setInitialValues(
              JSON.parse(JSON.stringify(RESPONTANT_LIFE_CYCLE)),
              case_response,
              true
            );
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        navigate("/dashboard/cases");
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }

    if (id && triggerCaseCall) {
      getCaseById(id);
      setTriggerCaseCall(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCaseCall]);

  useEffect(() => {
    (async function () {
      try {
        const response = await CaseService.getTranscriptionType(id);
        if (response) {
          setType(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function enableLiveTranscription(id, resolutionKind) {
      try {
        if (resolutionKind !== "negotiation") {
          const transcriptionControl =
            await CaseService.enableLiveTranscription(id);
          setStatusCheck(transcriptionControl);
        } else {
        }
        // return;
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (id && resolutionKindData) {
      enableLiveTranscription(id, resolutionKindData);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, resolutionKindData]);

  /** This function is used to set the inital values for the component**/
  const setInitialValues = (caesLifeCycle, caseDetails, isRespondent) => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (caesLifeCycle.some((el) => el.key === caseDetails.status)) {
        const caseState = caesLifeCycle.find(
          (el) => el.key === caseDetails.status
        );
        setCurrentState(caseState);
        setShowCta(isRespondent || !isRespondent || caseState?.index > 3);
        displayCaseCTA(caseState, caseDetails);
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find((el) => el.index === 2));
        } else {
          if (caseDetails.status === "finalAward") {
            setCurrentState({
              label: "Final Award",
              key: "finalAward",
              index: 5,
              enableSelection: false,
              cta: [],
            });
          } else {
            setCurrentState(caesLifeCycle.find((el) => el.index === 1));
          }
        }
      } else if (caseDetails.status === "finalAward") {
        setCurrentState({
          label: "Final Award",
          key: "finalAward",
          index: 5,
          enableSelection: false,
          cta: [],
        });
      }
    }
  };

  /** This function is used to display the CTA based on the status */
  const displayCaseCTA = (currentState, caseDetails) => {
    if (!_.isEmpty(currentState)) {
      if (caseDetails?.resolutionKind !== "negotiation") {
        if (caseDetails.status === NEGOTIATION_ONGOING) {
          setButtonToDisplay(
            currentState?.cta?.filter((el) => el.type === "QUIT")
          );
        } else {
          setButtonToDisplay(currentState?.cta?.filter((el) => el.displayBtn));
        }
      } else {
        if (caseDetails.status === NEGOTIATION_ONGOING) {
          setButtonToDisplay(
            currentState?.cta?.filter(
              (el) => el.type === "QUIT" || el.type === "ACCEPT"
            )
          );
        } else {
          setButtonToDisplay(currentState?.cta?.filter((el) => el.displayBtn));
        }
      }
    }
  };

  /**THis function is used for Navigating the case details page */
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case _.snakeCase(caseDetails?.resolutionKind):
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      case "my_cases":
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  const callSubmitOffer = () => {
    setLoader({ state: true, message: "Updating case details..." });
    callSendOffer(id, formikRef)
      .then(
        async () => {
          setLoader({ state: false });
          setTriggerCaseCall(true);
          enqueueSnackbar("Response Sent Successfully", {
            variant: "success",
          });
        },
        (err) => {
          setLoader({ state: false });
          enqueueSnackbar(getErrorMessage(err), {
            variant: "error",
          });
        }
      )
      .finally(() => {
        hideAlert();
      });
  };

  /**
   * @description Function to trigger the Quit case Details
   */
  const triggerQuitCase = () => {
    setLoader({ state: true, message: "Updating case details..." });
    callQuitCase(id).then(
      (_) => {
        setLoader({ state: false });
        setTriggerCaseCall(true);
      },
      (err) => {
        setLoader({ state: false });
      }
    );
  };

  const showPaymentAlert = async (type) => {
    try {
      setLoader({ state: true, message: "loading..." });
      const negotiationSuccessFee =
        (await CaseService.getCase(id, "?estimateFeeKind=successFee"))?.fee ||
        {};
      setDialogData({
        primaryBtnText: "Go to Case Cart",
        secondaryBtnText: "I'll Pay Later",
        desc: (
          <>
            To download your agreement for{" "}
            <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
              Case ID {id}
            </b>{" "}
            please go to case cart and complete the payment towards{" "}
            {caseDetails?.resolutionKind} Agreement fee:{" "}
            <b style={{ fontFamily: `${theme.fonts.primaryFontExtraBold}` }}>
              {negotiationSuccessFee?.unit}{" "}
              {parseFloat(negotiationSuccessFee?.total).toFixed(2)}
            </b>
          </>
        ),
        heading: "Case moved to Payment",
        buttonType: type,
      });
      setOpen(true);
    } catch (err) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  /**THis case is used to decide what will happend when the button is trigggered */
  const onbuttonClick = (type) => {
    switch (type) {
      case "QUIT": {
        setDialogData({
          primaryBtnText: "Cancel",
          secondaryBtnText: "Quit",
          desc: `Please note once you quit, you cannot continue your ${caseDetails?.resolutionKind}.`,
          heading: "Do you really want to Quit your case?",
          buttonType: type,
          status: true,
          descriptionTextStyle: {
            textAlign: "left",
          },
        });
        setOpen(true);
        break;
      }
      case "MOVE_TO_CART": {
        showPaymentAlert(type);
        break;
      }

      case "MOVE_TO_AGREEMENT": {
        const currentStatus = isRespondent
          ? RESPONTANT_LIFE_CYCLE.find(
              (el) => el.key === CLAIMENT_PAID_NEGOTIATION
            )
          : CLAIMANT_LIFE_CYCLE.find(
              (el) => el.key === RESPONDENT_PAID_NEGOTIATION
            );
        setCurrentState(currentStatus);
        displayCaseCTA(currentStatus, caseDetails);
        break;
      }

      default: {
        break;
      }
    }
  };

  /**Function to trigger the success model */
  const clickPrimaryBtn = (type) => {
    setOpen(false);
    switch (type) {
      case "MOVE_TO_CART": {
        setTriggerCount(true);
        navigate("/dashboard/cart");
        break;
      }
      case "SUBMIT_OFFER": {
        callSubmitOffer();
        break;
      }

      default: {
        break;
      }
    }
  };

  const clickSecondarybtn = (type, data) => {
    setOpen(false);
    switch (type) {
      case "QUIT": {
        triggerQuitCase();
        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper state={state}>
        <LifeCycleContainer>
          <StyledLabel>Case Lifecycle</StyledLabel>
          {!_.isEmpty(caseDetails) && (
            <LifeCycle
              items={isRespondent ? RESPONTANT_LIFE_CYCLE : CLAIMANT_LIFE_CYCLE}
              selectedIndex={currentState?.index}
              isMediation={caseDetails?.resolutionKind !== "negotiation"}
              resolutionKind={caseDetails?.resolutionKind}
              kind={isRespondent}
              status={currentState?.status}
              currentStateKey={currentState?.key}
            />
          )}
        </LifeCycleContainer>
        <Spacing>
          <CaseDetails
            isActionsDisabled={
              currentState?.key === NEGOTIATION_REACHED ||
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
              currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
              currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
              currentState?.key === BOTH_PAID_NEGOTIATION ||
              currentState?.key === AWAITING_RESPONDANT_QUIT ||
              currentState?.key === "finalAward"
            }
            isViewAction={
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER
            }
            {...{
              setState,
              id,
              MTRef,
              partyList,
              docUploadModal,
              setDocUploadModal,
              setFields,
              setTriggerCaseCall,
            }}
            caseDetails={caseDetails}
          />
        </Spacing>
        <Manager>
          {caseDetails?.caseManager?.name && (
            <Block>
              <CaseManager
                caseManager={caseDetails?.caseManager}
                hideChat={caseDetails?.resolutionKind !== "negotiation"}
              />
            </Block>
          )}

          {caseDetails?.resolutionKind !== "negotiation" &&
          caseDetails?.requestedMediators?.length ? (
            <>
              {caseDetails?.requestedMediators
                ?.filter((el) => el?.id)
                ?.map((mediator, index) => (
                  <Block key={index}>
                    <CaseManager
                      title={"Arbitrator"}
                      hideChat={true}
                      caseManager={mediator}
                    />
                  </Block>
                ))}
            </>
          ) : null}
          {caseDetails?.resolutionKind &&
            caseDetails?.resolutionKind !== "negotiation" && (
              <Block>
                <BigBlueButton
                  id={caseDetails?.id}
                  status={caseDetails?.status}
                  resolutionKind={caseDetails?.resolutionKind}
                  type={`Join ${_.capitalize(
                    caseDetails?.resolutionKind
                  )} Session`}
                />
              </Block>
            )}
          {statusCheck?.enableTranscription === "yes"
            ? caseDetails?.resolutionKind &&
              caseDetails?.resolutionKind !== "negotiation" && (
                <Block>
                  <CaseManagerCardData>
                    <PrimaryCTAButton
                      // style={{ width: "258px" }}
                      onClick={() =>
                        navigate(
                          type?.crossExamination
                            ? `/dashboard/cases/${id}/cross-examination?caseType=${caseDetails?.resolutionKind}`
                            : `/dashboard/cases/${id}/livetranscription?caseType=${caseDetails?.resolutionKind}`,
                          { state: type?.crossExamination ? type : caseDetails }
                        )
                      }
                    >
                      {type.crossExamination
                        ? "Cross Examination"
                        : "Live Transcription"}
                    </PrimaryCTAButton>
                  </CaseManagerCardData>
                </Block>
              )
            : ""}
        </Manager>

        {negotiationTypeState({
          isLocalTranscriptionServer: type?.isLocalTranscriptionServer,
          ownerId: caseDetails?.claimantPartyId,
          respondentId: caseDetails?.respondentPartyId,
          currentState,
          isRespondent,
          formikRef,
          caseDetails,
          showCta,
          comments,
          setComments,
          partyList,
        })}
        <Spacing>
          {showCta && (
            <CaseCTA
              cta={buttonToDisplay}
              disableButton={false}
              onbuttonClick={(type) => onbuttonClick(type)}
            />
          )}
        </Spacing>

        {/* This is for Modal */}
        <div>
          <AlertDialog
            isOpen={open}
            clickSecondarybtn={() =>
              clickSecondarybtn(dialogData.buttonType, false)
            }
            primaryBtnText={dialogData.primaryBtnText}
            status={dialogData.status}
            secondaryBtnText={dialogData.secondaryBtnText}
            desc={dialogData.desc}
            heading={dialogData.heading}
            clickPrimaryBtn={() => clickPrimaryBtn(dialogData.buttonType, true)}
            onDialogClose={() => setOpen(false)}
            descriptionTextStyle={{
              textAlign: "center",
            }}
          />
        </div>
      </PageWrapper>
      <DrawerTableCaseDocument
        {...{
          state,
          setState,
          id,
          MTRef,
          caseDetails,
        }}
        isActionsDisabled={
          currentState?.key === NEGOTIATION_REACHED ||
          currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
          currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
          currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
          currentState?.key === BOTH_PAID_NEGOTIATION ||
          currentState?.key === AWAITING_RESPONDANT_QUIT ||
          currentState?.key === "finalAward"
        }
        type={caseDetails?.resolutionKind}
        agentRole={caseDetails?.agentRole}
        agreementsAndAwards={caseDetails?.agreementsAndAwards}
        setTriggerCaseCall={setTriggerCaseCall}
      />
      <ExtraFieldsDrawer {...{ fields, setFields, caseDetails, id }} />
    </Fragment>
  );
}
