import React, { useState } from "react";
import { navigate, useParams } from "@reach/router";
import CaseService from "../../services/CaseService";
import theme from "../../assets/theme";
import { getErrorMessage } from "../../helpers/functions";
import useLoader from "../../hooks/useLoader";
import { useSnackbar } from "notistack";
import COLORS from "../../assets/Colors";
import styled from "styled-components";
import { CheckboxContainer, CustomCheckbox } from "../common/FormInputs";
import { Modal } from "@material-ui/core";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../common/Buttons";
import { Formik, Form } from "formik";
import FormField from "../common/FormField/FormField";
import { CreateGenericMeeting } from "../../helpers/validationSchema";

export default function Index() {
  const params = useParams();
  const [open, setOpen] = useState(true);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const meetingCode = params?.code;

  const inputs = [
    {
      name: "name",
      type: "text",
      label: "Enter your Name",
      required: true,
      autoFocus: true,
    },
    {
      name: "email",
      type: "email",
      label: "Enter your Email",
      required: true,
    },
  ];

  async function onFormSubmit(values) {
    setLoader({ state: true, message: "Updating Meeting details..." });
    try {
      const payload = {
        guestUserName: values?.name,
        guestUserMail: values?.email,
      };
      const res = await CaseService.getGuestUserMeeting(payload, meetingCode);
      window.open(res, "_self");
      if (res?.message) {
        enqueueSnackbar("Meeting Open", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  }

  return (
    <Modal open={open}>
      <DrawerContainer>
        <Container>
          <HeadingContainer>
            <Heading>Join the meeting room</Heading>
            <CloseModal
              onClick={() => navigate("/")}
              src={require("../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <FormContainer>
            <Formik
              initialValues={{
                name: "",
                email: "",
                checked: false,
              }}
              enableReinitialize
              validateOnBlur
              validateOnChange
              validationSchema={CreateGenericMeeting}
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
                isValid,
              }) => (
                <>
                  <Form className="w100p" onSubmit={handleSubmit}>
                    <Label
                      style={{
                        fontFamily: theme.fonts.primaryFontExtraBold,
                        marginBottom: "8px",
                        color: COLORS.LOGOUT_RED,
                      }}
                    >
                      We may record the meeting for case proceedings and
                      transcription purposes
                    </Label>{" "}
                    {inputs.map((input, index) => {
                      return (
                        <FormField
                          key={index}
                          {...{
                            touched,
                            errors,
                            input,
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }}
                        />
                      );
                    })}
                    <CheckboxContainer
                      style={{ marginBottom: 20, marginTop: 10 }}
                    >
                      <CustomCheckbox
                        name="checked"
                        value={values?.checked}
                        onChange={handleChange}
                      />
                      <CheckBoxLabel>
                        I agree with all{" "}
                        <HyperLink
                          onClick={() =>
                            window.open(
                              "https://user.indiaiac.org/privacy-policy",
                            )
                          }
                        >
                          Privacy Policy
                        </HyperLink>{" "}
                        ,{" "}
                        <HyperLink
                          onClick={() =>
                            window.open("https://user.indiaiac.org/regulations")
                          }
                        >
                          Regulations
                        </HyperLink>{" "}
                        and{" "}
                        <HyperLink
                          onClick={() =>
                            window.open(
                              "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/160/1717996066986-eb11d487-863e-4e34-ad05-41953f3784dd/India%20Internationa%20Arbitration%20Centre%28Conduct%20of%20Arbitration%29%20Regulation%2C%202023%28English%20Version%29.pdf",
                            )
                          }
                        >
                          Rules
                        </HyperLink>{" "}
                        of IIAC
                      </CheckBoxLabel>
                    </CheckboxContainer>
                    <ButtonContainer>
                      <PrimaryOutlinedCTAButton
                        style={{ width: "45%" }}
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </PrimaryOutlinedCTAButton>
                      <PrimaryCTAButton
                        style={{ width: "45%" }}
                        disabled={!isValid || !values?.checked}
                        onClick={handleSubmit}
                      >
                        Submit
                      </PrimaryCTAButton>
                    </ButtonContainer>
                  </Form>
                </>
              )}
            </Formik>
          </FormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12.5px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.div`
  padding: 15px;
  .link-container {
    margin: 12px;
  }
`;

const CheckBoxLabel = styled.label`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

export const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
