import React from "react";
import {
  CaseManagerCard,
  CaseManagerTitle,
  Flex,
  CaseManagerProfile,
  CaseManagerName,
  Column,
} from "../styles";
import DrawerModal from "./DrawerModal/DrawerModal";
import { useState } from "react";

export default function CaseManager({
  title = "IIAC Counsel",
  hideChat = false,
  disabled = false,
  caseManager,
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard disabled={disabled}>
      <Column>
        <CaseManagerTitle>
          {caseManager?.presidingArbitrator === "yes"
            ? "Presiding Arbitrator"
            : title}
        </CaseManagerTitle>
        <Flex>
          <CaseManagerProfile
            src={
              caseManager?.avatarUrl
                ? caseManager?.avatarUrl
                : require("../../../../assets/images/userPlaceholder.svg")
            }
          />
          <CaseManagerName onClick={(e) => setModal(true)}>
            {caseManager?.name}
          </CaseManagerName>
        </Flex>
      </Column>
      <DrawerModal
        {...{
          modal,
          setModal,
        }}
        type={title}
        id={caseManager?.id}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}
