import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useLoader from "../../../hooks/useLoader";
import useSettings from "../../../hooks/useSettings";
import CaseService from "../../../services/CaseService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function TableWithAccordion({
  id,
  title,
  agreementsAndAwards,
  setTriggerCaseCall,
}) {
  const classes = useStyles();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { setting } = useSettings();
  const [expanded, setExpanded] = useState(false);

  const arbitrationTemplateUrl =
    typeof setting?.arbitrationTemplateUrl === "string"
      ? setting?.arbitrationTemplateUrl.split(",")
      : setting?.arbitrationTemplateUrl;

  const agreementsAndAwardsDoc =
    typeof agreementsAndAwards === "string"
      ? agreementsAndAwards.split(",")
      : agreementsAndAwards;

  const refreshAwards = async (id) => {
    try {
      setLoader({ state: true, message: `refresh awards` });
      const res = await CaseService.refreshAwards(id);
      if (res) {
        enqueueSnackbar("Success", {
          variant: "success",
        });
        setTriggerCaseCall(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <>
      <Accordion className={classes.root} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded((e) => !e)} />}
        >
          <AccordionHeading
            style={{ marginLeft: 24 }}
            onClick={() => setExpanded((e) => !e)}
          >
            {title}
          </AccordionHeading>
          <Actions>
            <HyperLink onClick={() => refreshAwards(id)}>
              Refresh Template
            </HyperLink>
          </Actions>
        </AccordionSummary>
        <div
          style={{
            paddingLeft: "25px",
            paddingBottom: "20px",
            paddingTop: "auto",
            overflow: "auto",
          }}
        >
          {agreementsAndAwardsDoc
            ? agreementsAndAwardsDoc.map((agreementsAndAwardsDoc, index) => (
                <div key={agreementsAndAwardsDoc + index}>
                  <H2
                    style={{ marginBottom: "15px", color: COLORS.BTN_GREEN }}
                    onClick={() => window.open(agreementsAndAwardsDoc)}
                  >
                    {agreementsAndAwardsDoc?.split("/").length > 0
                      ? agreementsAndAwardsDoc.split("?")[0].split("/").pop()
                      : "-"}
                  </H2>
                </div>
              ))
            : arbitrationTemplateUrl
            ? arbitrationTemplateUrl.map((arbitrationTemplateUrl, index) => (
                <div key={arbitrationTemplateUrl + index}>
                  <H2
                    style={{ marginBottom: "15px", color: COLORS.BTN_GREEN }}
                    onClick={() => window.open(arbitrationTemplateUrl)}
                  >
                    {arbitrationTemplateUrl?.split("/").length > 0
                      ? arbitrationTemplateUrl.split("?")[0].split("/").pop()
                      : "-"}
                  </H2>
                </div>
              ))
            : ""}
        </div>
      </Accordion>
    </>
  );
}
export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;

const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  margin-bottom: 15px;
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #00838c;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;

export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;
