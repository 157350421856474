import axios from "axios";
import { navigate } from "@reach/router";

const EXCLUDE_URL = ["sessions"];

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 30000,
});

/**
 * Request Wrapper with default success/error actions
 */
const Api = async (
  config = { method: "GET", data: {}, url: "" },
  shouldAppendToken = true
) => {
  // Success
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  // Error
  const onError = (error) => {
    if (error?.message === "Network Error") {
      return Promise.reject(
        "You have lost Network Connectivity. Please check your Network Settings"
      );
    }
    if (error.response.status === 401) {
      /**
       * If 401
       * Clear the token from offline store
       * and navigate to Initial Stack using Navigation Service
       */
      // User.clearToken();
      if (!EXCLUDE_URL.some((el) => el === config.url)) {
        if (error.response.data.logout) {
          localStorage.removeItem("auth_token");
          navigate("/login");
        } else {
          localStorage.removeItem("auth_token");
          navigate("/un-authorized");
        }
      }
    }
    if (error.response.status === 500) {
      //navigate("/dashboard");
      navigate("/internal-error");
    }

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      return Promise.reject(error.response.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      return Promise.reject(error.message);
    }
  };

  // Append headers
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-usage-mode": "agent",
  };

  // Append auth token
  if (shouldAppendToken) {
    try {
      const token = await localStorage.getItem("auth_token");
      if (token) {
        headers["Authorization"] = token;
      } else {
        return Promise.reject("Auth Token not found");
      }
    } catch (error) {
      // Token is not found
      return Promise.reject(error.message);
    }
  }

  // Set headers
  axiosClient.defaults.headers = headers;

  return axiosClient(config).then(onSuccess).catch(onError);
};

export default Api;
