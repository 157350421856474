import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  ARBITRATION_REFERENCE,
  NOTICE_TO_ARBITRATE,
  APPOINTMENT_OF_ARBITRATOR,
  ACCEPTANCE_BY_ARBITRATOR,
  FIRST_NOTICE_FIRST_HEARING_INTIMATION,
  FILING_STATEMENT_OF_CLAIM,
  FILING_OF_SECTION_SEVENTEEN,
  SECTION_SEVENTEEN_ORDER_PASSED,
  FILING_OF_STATEMENT_OF_DEFENCE,
  REJOINDER_FROM_CLAIMNT,
  SECOND_NOTICE_MOM,
  SURREJOINDER_FROM_RESPONDENT,
  CROSSEXAMINATION_CLAIMANT_WITNESS,
  CROSSEXAMINATION_RESPONDENT_WITNESS,
  ARGUMENT,
  RESERVED_FOR_AWARD,
} from "../../../../helpers/constants";
import CommentBox from "../common/CommentBox";
import CaseAgreement from "../common/CaseAgreement";
import AgreementDocCard from "../common/AgreementDocCard";
import { Spacing } from "../styles";
import _ from "lodash";

function MediationState({
  currentState,
  isRespondent,
  ownerId,
  caseDetails,
  showCta,
  comments,
  setComments,
  partyList,
  isLocalTranscriptionServer,
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */

  const RenderCommentBox = ({
    status,
    expand,
    errorText,
    comments,
    caseId,
    setComments,
    disabled,
    isLocalTranscriptionServer,
  }) => {
    return (
      <CommentBox
        title={`${_.capitalize(caseDetails?.resolutionKind)} Process`}
        expand={expand}
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        caseId={caseId}
        comments={comments}
        disabled={disabled}
        setComments={setComments}
        isRespondent={isRespondent}
        hasCaseManager={!_.isEmpty(caseDetails?.caseManager)}
        hasMediator={
          caseDetails?.requestedMediators?.length &&
          caseDetails?.requestedMediators?.some((el) => el?.id)
        }
        hideAddComment={
          currentState?.key === AWAITING_RESPONDANT ||
          currentState?.key === NEGOTIATION_ONGOING ||
          currentState?.key === ARBITRATION_REFERENCE ||
          currentState?.key === NOTICE_TO_ARBITRATE ||
          currentState?.key === APPOINTMENT_OF_ARBITRATOR ||
          currentState?.key === ACCEPTANCE_BY_ARBITRATOR ||
          currentState?.key === FIRST_NOTICE_FIRST_HEARING_INTIMATION ||
          currentState?.key === FILING_STATEMENT_OF_CLAIM ||
          currentState?.key === FILING_OF_SECTION_SEVENTEEN ||
          currentState?.key === SECTION_SEVENTEEN_ORDER_PASSED ||
          currentState?.key === FILING_OF_STATEMENT_OF_DEFENCE ||
          currentState?.key === REJOINDER_FROM_CLAIMNT ||
          currentState?.key === SECOND_NOTICE_MOM ||
          currentState?.key === SURREJOINDER_FROM_RESPONDENT ||
          currentState?.key === CROSSEXAMINATION_CLAIMANT_WITNESS ||
          currentState?.key === CROSSEXAMINATION_RESPONDENT_WITNESS ||
          currentState?.key === ARGUMENT ||
          currentState?.key === RESERVED_FOR_AWARD
        }
        // hideAddComment={
        //   !NEGOTIATION_ONGOING
        //     ? currentState?.key !== AWAITING_RESPONDANT
        //     : currentState?.key !== NEGOTIATION_ONGOING
        // }
        partyList={partyList}
        isLocalTranscriptionServer={isLocalTranscriptionServer}
        kind={
          caseDetails?.resolutionKind === "mediation"
            ? "Mediator"
            : "Arbitrator"
        }
      />
    );
  };

  const AgreementSection = () => {
    return (
      <>
        <Spacing>
          <CaseAgreement
            title={`Final Award Summary for Case ID: ${caseDetails?.id}`}
            message={caseDetails?.negotiationSummary}
            summaryStatus={caseDetails?.summaryStatus === "completed"}
            isPaid={currentState?.cta?.length === 0}
          />
        </Spacing>
        <Spacing>
          <AgreementDocCard
            id={caseDetails?.id}
            status={caseDetails.status}
            isRespondent={isRespondent}
            hasRating={caseDetails?.rating}
            hasAgreement={caseDetails?.hasAgreementDocument}
            type={`Download`}
          />
        </Spacing>
      </>
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <Spacing>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
            // disabled={true}
          />
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by IIAC Counsel"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case RESPONDANT_ONBOARDED:
      return (
        <Spacing>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Claimant"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Respondent"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_REACHED:
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case ARBITRATION_REFERENCE: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NOTICE_TO_ARBITRATE: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case APPOINTMENT_OF_ARBITRATOR: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case ACCEPTANCE_BY_ARBITRATOR: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case FIRST_NOTICE_FIRST_HEARING_INTIMATION: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case FILING_STATEMENT_OF_CLAIM: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case FILING_OF_SECTION_SEVENTEEN: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case SECTION_SEVENTEEN_ORDER_PASSED: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case FILING_OF_STATEMENT_OF_DEFENCE: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case REJOINDER_FROM_CLAIMNT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case SURREJOINDER_FROM_RESPONDENT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case SECOND_NOTICE_MOM: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case CROSSEXAMINATION_CLAIMANT_WITNESS: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case CROSSEXAMINATION_RESPONDENT_WITNESS: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case ARGUMENT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case RESERVED_FOR_AWARD: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "finalAward": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    default:
      return null;
  }
}

export default React.memo(MediationState);
