import React from "react";
import { Header } from "../../internal/Header/Header";
import {
  HeroContainerWrap,
  HeroContainer,
  HeroHeading,
  H1,
  H3,
  WidthMax,
} from "./Styles";
import Footer from "./Footer";
import Cookies from "./Cookies.js";
import useIfLoggedIn from "../../../hooks/useIfLoggedIn";
import labels from "../../../helpers/labels.json";
import { sanitize } from "dompurify";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../common/Buttons.js";
import { navigate } from "@reach/router";

const Index = () => {
  useIfLoggedIn();

  return (
    <>
      <Header LandingPage NavPages>
        <WidthMax>
          <Cookies />
          <HeroContainerWrap id="hero">
            <HeroContainer>
              <div className="hero-container">
                <ProfileImage
                  className="hero-image"
                  src={require("../../../assets/images/landing-image.png")}
                  alt="hero-image"
                />
              </div>
              <HeroHeading>
                <H1
                  dangerouslySetInnerHTML={{
                    __html: sanitize(labels.landing_title),
                  }}
                />
                <H3>{labels["landing.subheading"]}</H3>
                <H3 style={{ marginBottom: 20 }}>
                  {labels["landing.subheading2"]}
                </H3>
                <div style={{ maxWidth: 150, marginTop: 10 }}>
                  <PrimaryCTAButton
                    style={{
                      marginLeft: "unset",
                    }}
                    onClick={() => navigate("/neutral/arbitrators")}
                  >
                    Browse
                  </PrimaryCTAButton>
                </div>
              </HeroHeading>
            </HeroContainer>
          </HeroContainerWrap>
          <Footer />
        </WidthMax>
      </Header>
    </>
  );
};
export default Index;

export const ProfileImage = styled.img``;
