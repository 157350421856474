/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
  SmallText,
  Center,
} from "../../../styles/component/style";
import useUser from "../../../hooks/useUser";
import { PrimaryCTAButton } from "../Buttons";
import { Formik } from "formik";
import { signupValidationSchema } from "../../../helpers/validationSchema";
import FormField from "../FormField/FormField";
import _ from "lodash";
import AuthService from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import { navigate, Link } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import GLogin from "../GoogleLogin";

export const Signup = ({
  heading = "SignUp",
  inputs = [],
  tcLink = "",
  submitLabel = labels["proceed"],
  loginLink = "",
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { state } = location;
  const { setToken } = useUser();

  const [email, setEmail] = useState("");
  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Please wait..." });
      let payload = _.pick(values, ["email", "mobile", "password"]);

      payload.name = values.fullname;
      const response = await AuthService.verifications(payload);
      if (response && payload.mobile && payload.mobile.includes("+91")) {
        enqueueSnackbar("OTP Sent Successfully", {
          variant: "success",
        });
        navigate("/otp", {
          state: {
            payload,
            values,
          },
        });
      } else {
        if (response && response.token) {
          setToken(response.token);
          navigate("/create-profile");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  useEffect(() => {
    if (location?.search) {
      const payload = queryString.parse(location.search);
      if (payload.email) {
        setEmail(payload.email);
      }
    }
  }, [location]);

  const signUpwithGoogle = (res) => {
    if (res?.tokenObj?.id_token) {
      navigate("/google-signup", {
        state: {
          idToken: res?.tokenObj?.id_token,
          profileObj: res?.profileObj,
        },
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          fullname: state?.payload?.fullname || "",
          email: email ? email : state?.payload?.email || "",
          mobile: state?.payload?.mobile || "",
          password: state?.payload?.password || "",
          confirmPassword: state?.payload?.confirmPassword || "",
        }}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={signupValidationSchema}
        validateOnBlur
        validateOnChange
      >
        {({
          values,
          handleChange,
          errors,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <CardWrapper onSubmit={handleSubmit}>
            <HeadingContainer>
              <Header3>{heading}</Header3>
            </HeadingContainer>
            <ContentContainer>
              {inputs.map((input, index) => {
                return (
                  <FormField
                    disabled={email.length && input.type === "email"}
                    key={index}
                    {...{
                      touched,
                      errors,
                      input,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                );
              })}
            </ContentContainer>
            {tcLink && (
              <div className="pt20 pb10 center">
                <SmallText>
                  By signing up I agree with all{" "}
                  <a href={"/privacy-policy"} target="_blank">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href={"/regulations"} target="_blank">
                    Regulations
                  </a>
                </SmallText>
              </div>
            )}
            <div className="pt20 pb10">
              <PrimaryCTAButton
                type="submit"
                onClick={handleSubmit}
                disabled={disableSubmit}
              >
                {submitLabel}
              </PrimaryCTAButton>
            </div>

            {loginLink && (
              <div className="center">
                <SmallText>
                  Have an account already? <Link to={"/login"}>Login here</Link>
                </SmallText>
              </div>
            )}
            <Center className="openSans-SemiBold pt10 pb10">Or</Center>
            <div className="pb20">
              <GLogin
                label="Signup with Google"
                className="p8"
                onSuccess={signUpwithGoogle}
              />
            </div>
          </CardWrapper>
        )}
      </Formik>
    </>
  );
};
