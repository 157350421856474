import {
  Breadcrumbs,
  capitalize,
  MenuItem,
  MenuList,
  Select,
  Link,
} from "@material-ui/core";
import React from "react";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import CloseIcon from "@material-ui/icons/Close";
import { CustomCheckbox } from "../../common/FormInputs";
import {
  FilterBarContainer,
  FilterHead,
  FilterSubHead,
  ListScroll,
  SearchIcon,
  SearchBar,
  SearchBarContainer,
  StyledDropdownIcon,
  StyledSelectFormControl,
} from "./Styles";

function FilterBar({
  classes,
  neutral,
  filterBar,
  filterRef,
  experienceSelections,
  languageSelections,
  locationSelections,
  inputText,
  checkedLanguages,
  handleCheck,
  handleClick,
  setInputText,
  setFilterBar,
  setCheckedLanguages,
  filterData,
  paginate,
  ...props
}) {
  return (
    <FilterBarContainer style={{ display: filterBar }}>
      <div style={{ display: "flex", justifyItems: "space-between" }}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.displayType}>
          <Link
            color="inherit"
            href="/"
            onClick={handleClick}
            className={classes.link}
          >
            <HomeIcon className={classes.icon} />
            IIAC
          </Link>
          <Link
            color="inherit"
            href={"/neutral/" + neutral}
            onClick={handleClick}
            className={classes.link}
          >
            <PeopleAltIcon className={classes.icon} />
            {capitalize(neutral)}
          </Link>
        </Breadcrumbs>
        {filterBar === "inline" ? (
          <div
            onClick={() => setFilterBar("none")}
            style={{
              display: "flex",
              cursor: "pointer",
              position: "absolute",
              right: "20px",
            }}
          >
            <CloseIcon style={{ fontSize: "large", margin: "auto" }} />
          </div>
        ) : null}
      </div>
      <SearchBarContainer>
        <SearchIcon
          src={require("../../../assets/images/searchBar.svg")}
          alt="search_icon"
        />
        <SearchBar
          placeholder="Name, Expertise or Qualification "
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
            filterData(e.target.value, filterRef.current);
            paginate(1);
          }}
          autoFocus
        />
        <CloseIcon
          style={{ fontSize: "large", margin: "auto 8px", color: "#acb1c2" }}
          onClick={() => {
            setInputText("");
            filterData("", filterRef.current);
          }}
        />
      </SearchBarContainer>
      <div>
        {/* Filter heading */}
        <FilterHead>Filters </FilterHead>
        <span
          style={{
            fontSize: "12px",
            textDecoration: "underline",
            color: COLORS.BTN_GREEN,
            cursor: "pointer",
            fontFamily: theme.fonts.primaryFontBold,
          }}
          onClick={() => {
            filterRef.current = {
              experience: "",
              location: "",
              language: ["English"],
            };
            setCheckedLanguages([]);
          }}
        >
          Remove All Filters
        </span>
      </div>
      {/* Experience Filter */}
      <FilterSubHead style={{ fontSize: "16px" }}>Experience</FilterSubHead>
      <StyledSelectFormControl
        style={{ marginTop: "10px" }}
        className={"input-white"}
        variant="outlined"
      >
        <Select
          value={filterRef.current.experience}
          displayEmpty
          IconComponent={() => (
            <StyledDropdownIcon
              alt="select"
              src={require("../../../assets/images/dropdownIcon.svg")}
            />
          )}
          onChange={(e) => {
            filterRef.current = {
              ...filterRef.current,
              experience: e.target.value,
            };
            filterData(inputText, filterRef.current);
            paginate(1);
          }}
        >
          <MenuItem
            style={{ fontFamily: theme.fonts.primaryFontRegular }}
            value=""
          >
            All
          </MenuItem>
          {experienceSelections.map((experience, index) => (
            <MenuItem
              style={{ fontFamily: theme.fonts.primaryFontRegular }}
              key={index + 1}
              value={experience}
            >
              {capitalize(experience)}
            </MenuItem>
          ))}
        </Select>
      </StyledSelectFormControl>

      {/* Location Filter */}
      <FilterSubHead style={{ fontSize: "16px" }}>Location</FilterSubHead>
      <StyledSelectFormControl
        style={{ marginTop: "10px" }}
        className={"input-white"}
        variant="outlined"
      >
        <Select
          value={filterRef.current.location}
          displayEmpty
          IconComponent={() => (
            <StyledDropdownIcon
              alt="select"
              src={require("../../../assets/images/dropdownIcon.svg")}
            />
          )}
          onChange={(e) => {
            filterRef.current = {
              ...filterRef.current,
              location: e.target.value,
            };
            filterData(inputText, filterRef.current);
            paginate(1);
          }}
        >
          <MenuItem
            style={{ fontFamily: theme.fonts.primaryFontRegular }}
            value=""
          >
            All
          </MenuItem>
          {locationSelections.map((location, index) => (
            <MenuItem
              style={{ fontFamily: theme.fonts.primaryFontRegular }}
              key={index + 1}
              value={location}
            >
              {capitalize(location)}
            </MenuItem>
          ))}
        </Select>
      </StyledSelectFormControl>

      {/* Language Filter */}
      <FilterSubHead style={{ fontSize: "16px" }}>
        Languages Known
      </FilterSubHead>
      <ListScroll>
        <MenuList>
          {languageSelections.map((language) => {
            return (
              <MenuItem
                style={{
                  height: "30px",
                  fontFamily: theme.fonts.primaryFontRegular,
                }}
                key={language}
                value={language}
                onClick={(e) => {
                  handleCheck(language);
                }}
              >
                <CustomCheckbox checked={checkedLanguages.includes(language)} />
                <span>{capitalize(language)}</span>
              </MenuItem>
            );
          })}
        </MenuList>
      </ListScroll>
    </FilterBarContainer>
  );
}

export default FilterBar;
