import React, { useEffect } from "react";
import { ModelClausePage, HeadWrap } from "../Rules/Styles";

function ModelClauseRules(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <ModelClausePage>
          <HeadWrap> MODEL CLAUSE(S)</HeadWrap>
        </ModelClausePage>
      </div>
    </>
  );
}

export default ModelClauseRules;
