import React, { useState } from "react";

export const NotificationContext = React.createContext();

function NotificationProvider({ children }) {
  const [triggerNotify, setTriggerNotify] = useState(false);
  const [transaction, setTransaction] = useState();

  const value = React.useMemo(() => {
    return {
      triggerNotify,
      setTriggerNotify,
      transaction,
      setTransaction,
    };
  }, [triggerNotify, setTriggerNotify, transaction, setTransaction]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
