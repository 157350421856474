import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import { navigate } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import moment from "moment";
import CaseService from "../../../services/CaseService";
import FilterModal from "./modal";
import {
  getCaseLabelByStatus,
  getErrorMessage,
  numberFormat,
  parseTimeStamp,
} from "../../../helpers/functions";
import { Drawer, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import usePrevious from "../../../hooks/usePrevious";
import labels from "../../../helpers/labels.json";
import PartyService from "../../../services/PartyService";
import { Footer } from "../../internal/CaseCart/Styles";
import { PrimaryCTAButton } from "../Buttons";
import useLoader from "../../../hooks/useLoader";
import useCartCount from "../../../hooks/useCartCount";
import AlertDialog from "../../common/Alert";
import { DangerBtn } from "../../../styles/component/style";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles({
  root: {
    "& .MuiDrawer-paperAnchorRight": {
      zIndex: 2001,
      boxShadow: "1px 0px 20px 0px rgba(0,0,0,0.2)",
    },
  },
});

async function getCases(query = "", id, caseType) {
  try {
    let stats = {};
    const type = `?resolutionKind=${caseType}`;
    const response = await CaseService.getCases(query);
    if (id) {
      stats = await PartyService.partyCaseStats(id);
    } else {
      stats = await CaseService.caseStatsByCaseType(type);
    }
    return { ...response, stats };
  } catch (error) {
    throw error;
  }
}

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case labels["filters.draft"]:
      value = "draft";
      break;
    case labels["filters.awaiting_payment"]:
      key = "inCart";
      value = true;
      break;
    case labels["filters.under_resolution"]:
      value = "underResolution";
      break;
    case labels["filters.waiting_for_respondant"]:
      value = "awaitingRespondent";
      break;
    case labels["filters.settled"]:
      value = "settled";
      break;
    default:
      break;
  }
  return { key, value };
};

const initFilters = [
  { label: labels["filters.all"], key: "allCount", value: 0 },
  {
    label: labels["filters.waiting_for_respondant"],
    key: "awaitingRespondentOnBoardingCount",
    value: 0,
  },
  {
    label: labels["filters.under_resolution"],
    key: "underResolution",
    value: 0,
  },
  { label: labels["filters.settled"], key: "settled", value: 0 },
  { label: labels["filters.awaiting_payment"], key: "inCartCount", value: 0 },
  { label: labels["filters.draft"], key: "draftCount", value: 0 },
];

const partyRoles = [
  { label: labels["role.claimant"], value: "claimant" },
  { label: labels["role.respondent"], value: "respondent" },
];

let previousCaseType;

const CasesTable = ({
  partyId = null,
  status = null,
  caseType = null,
  draftSelection = false,
  currentCaseType,
}) => {
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initFilters);
  const placeholderText = labels["table.caseSearch"];
  const [selectedFilter, setSelectedFilter] = useState(
    status
      ? status === "Under Resolution"
        ? status
        : _.capitalize(status)
      : "All"
  );
  const [advanceFilter, setAdvanceFilter] = useState({
    parties: [],
    role: partyRoles[0],
  });
  const filterPreviousValue = usePrevious(advanceFilter);
  const [modal, setModal] = useState();
  const MTRef = useRef();
  const [noParty, setNoParty] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { setTriggerCount } = useCartCount();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  const [page, setPage] = useState(1);

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      window.open(meetingurl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  function handleNavigateToParty(type, id, Case) {
    if (Case?.editFlag && caseType === "arbitration") {
      navigate(`/dashboard/cases/create/${id}?caseType=${caseType}`, {
        state: {
          editFlag: Case?.editFlag,
        },
      });
    } else {
      switch (type) {
        case "draft":
          if (Case.totalClaimValue && caseType === "negotiation") {
            navigate(
              `/dashboard/cases/create/${id}/step-3?caseType=${caseType}`
            );
          } else if (
            Case.respondentName ||
            Case.respondentMobile ||
            Case.respondentEmail ||
            Case.respondentPanNumber ||
            Case?.respondentParties?.length
          ) {
            navigate(
              `/dashboard/cases/create/${id}/step-2?caseType=${caseType}`
            );
          } else {
            navigate(`/dashboard/cases/create/${id}?caseType=${caseType}`);
          }
          break;
        default:
          navigate(`/dashboard/cases/${id}?caseType=${caseType}`);
          break;
      }
    }
  }

  function setStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  const columns = [
    {
      field: "id",
      title: labels["table.case_id"],
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            handleNavigateToParty(rowData?.status, rowData?.id, rowData)
          }
        >
          {rowData.id}
        </HyperLink>
      ),
      tooltip: "Unique Identifier for the Case across IIAC platform",
      headerStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
    },
    {
      field: "title",
      title: labels["table.case_title"],
      render: (rowData) =>
        !isMobile ? (
          <Bold
            editFlag={rowData?.editFlag}
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData?.title?.length > 42 ? (
              <Bold style={{ inlineSize: 100, overflowWrap: "break-word" }}>
                {rowData?.title}
              </Bold>
            ) : (
              <Bold>{rowData?.title}</Bold>
            )}
          </Bold>
        ) : (
          <Bold
            editFlag={rowData?.editFlag}
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData.title}
          </Bold>
        ),
      headerStyle: {
        maxWidth: 180,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 180,
        padding: `12px 16px`,
      },
      sorting: false,
    },
    {
      title: labels["table.party"],
      render: (rowData) =>
        isMobile ? (
          <TextAlign
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData?.claimantParty?.name}
          </TextAlign>
        ) : (
          <TextAlign
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData?.claimantParty?.name?.length > 13 ? (
              <div
                style={{
                  cursor: "pointer",
                  inlineSize: 80,
                  overflowWrap: "break-word",
                }}
              >
                {rowData?.claimantParty?.name}
              </div>
            ) : (
              <TextAlign>{rowData?.claimantParty?.name}</TextAlign>
            )}
            {/* {rowData?.claimantParty?.name} */}
          </TextAlign>
        ),
      tooltip: "Parties representing to file the case",
      headerStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      sorting: false,
    },
    {
      field: "role",
      title: labels["table.party_role"],
      tooltip:
        "Your role is either Claimant or Respondent with respect to e case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleNavigateToParty(rowData?.status, rowData?.id, rowData)
          }
        >
          {_.startCase(rowData.agentRole)}
        </div>
      ),
      headerStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      sorting: false,
    },
    {
      field: "respondentName",
      title: labels["table.counterparty"],
      tooltip: "Party countering your case",
      sorting: false,
      render: (rowData) =>
        isMobile ? (
          <TextAlign
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData?.respondentParty?.name ? (
              rowData.respondentParty.name
            ) : rowData?.respondentName ? (
              rowData.respondentName
            ) : rowData?.respondentParties?.length ? (
              rowData?.respondentParties[0]?.name
            ) : (
              <Center>-</Center>
            )}
          </TextAlign>
        ) : (
          <TextAlign
            style={{
              cursor: "pointer",
              inlineSize: "80px",
              overflowWrap: "break-word",
            }}
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData?.respondentParty?.name ? (
              rowData.respondentParty.name
            ) : rowData?.respondentName ? (
              rowData.respondentName
            ) : rowData?.respondentParties?.length ? (
              rowData?.respondentParties[0]?.name
            ) : (
              <Center>-</Center>
            )}
          </TextAlign>
        ),
      headerStyle: {
        maxWidth: 110,
        width: 110,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 110,
        width: 110,
        padding: `12px 16px`,
      },
    },
    {
      field: "totalClaimValue",
      title: labels["table.claim_value"],
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const divStyle = {
          cursor: "pointer",
        };
        const caseItems = rowData.totalClaimValue;
        if (caseItems)
          return isMobile ? (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                handleNavigateToParty(rowData?.status, rowData?.id, rowData)
              }
            >
              {numberFormat(parseFloat(caseItems).toFixed(2), "INR")}
            </div>
          ) : (
            <TextAlign
              className="container"
              style={{
                cursor: "pointer",
                inlineSize: 130,
                overflowWrap: "break-word",
              }}
              onClick={() =>
                handleNavigateToParty(rowData?.status, rowData?.id, rowData)
              }
            >
              {numberFormat(
                parseFloat(caseItems).toFixed(2),
                rowData?.currencyUnit
              )}
            </TextAlign>
          );
        else
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                handleNavigateToParty(rowData?.status, rowData?.id, rowData)
              }
            >
              {"Non Monetary"}
            </div>
          );
      },
      sorting: false,
      headerStyle: {
        maxWidth: 128,
        width: 128,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 128,
        width: 128,
        padding: `12px 16px`,
      },
    },
    {
      field: "status",
      title: labels["table.status"],
      tooltip: "Status of the Case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleNavigateToParty(rowData?.status, rowData?.id, rowData)
          }
        >
          {getCaseLabelByStatus(rowData)}
        </div>
      ),
      headerStyle: {
        maxWidth: 165,
        width: 165,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 165,
        width: 165,
        padding: `12px 16px`,
      },
    },
    {
      field: "nextHearingDate",
      title: labels["table.nextHearingDate"],
      render: (rowData) => (
        <div
          style={{ marginLeft: 10, cursor: "pointer" }}
          onClick={() =>
            rowData?.resolutionKind !== "negotiation"
              ? getMeetingjoin(rowData.id)
              : null
          }
        >
          {rowData?.nextHearingDate?.date
            ? rowData?.nextHearingDate?.date
            : "-"}
        </div>
      ),
      sorting: true,
      headerStyle: {
        maxWidth: 102,
        width: 102,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 102,
        width: 102,
        padding: `12px 16px`,
      },
    },
    {
      field: "created_at",
      title: labels["table.created"],
      tooltip: "Case Created Date",
      headerStyle: {
        minWidth: 120,
      },
      render: (rowData) => (
        <Datecolumn>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleNavigateToParty(rowData?.status, rowData?.id, rowData)
            }
          >
            {rowData?.submittedOn
              ? moment(rowData.submittedOn).format("DD/MM/YYYY")
              : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
          </div>
          {rowData?.status === "draft" && (
            <div
              className="delete"
              onClick={() => deleteWaring(false, rowData?.id)}
            >
              {labels["table.delete"]}
            </div>
          )}
        </Datecolumn>
      ),
    },
  ];

  /**
   * @description Function to deleted the selected case
   */
  const deleteSelectedDraft = async () => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteSelectedCases();
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setOpen(false);
    }
  };

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange("");
      setOpen(false);
    }
  };

  useEffect(() => {
    if ((selectedFilter || advanceFilter) && filterPreviousValue && caseType) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, advanceFilter, caseType]);

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (previousCaseType !== currentCaseType) {
        params.page = 1;
        setPage(params.page);
      } else {
        setPage(params.page);
      }
      previousCaseType = currentCaseType;
      if (partyId) {
        params.partyId = partyId;
      }
      params.selected = true;
      let partyQueryString = "";
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      if (caseType) {
        params.resolutionKind = caseType;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      if (advanceFilter?.parties?.length > 0) {
        const partyId = advanceFilter?.parties?.map((el) => el.id);
        partyQueryString = queryString.stringify(
          { partyIds: partyId },
          { arrayFormat: "index" }
        );
        params.agentRole = advanceFilter.role.value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      if (partyQueryString) {
        stringParams += `&${partyQueryString}`;
      }
      getCases(stringParams, partyId, caseType)
        .then(async (result) => {
          if (result) {
            if (result.message === "Your are not associated with any Party") {
              resolve({
                data: [],
                page: 0,
                total: 0,
              });
              setState({
                data: [],
                lastPage: 1,
                page: 1,
                perPage: 10,
                total: 0,
              });
              setNoParty(true);
            } else {
              const rowData = result?.data?.map((row) => ({
                ...row,
                tableData: { checked: row?.isSelected },
              }));
              resolve({
                data: rowData,
                page: result.page - 1,
                total: result.total,
                lastPage: result.lastPage,
              });
              setState({
                data: rowData,
                page: result.page - 1,
                total: result.total,
                lastPage: result.lastPage,
              });
              setStats(result.stats);
            }
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  const handleCaseSelection = async (cases, data) => {
    const param = `?resolutionKind=${caseType}`;
    if (cases?.length >= 1 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        await CaseService.addAllCases(param);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else if (cases?.length === 0 && !data) {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        await CaseService.removeAllCases(param);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        MTRef.current.onQueryChange("");
      }
    } else {
      try {
        setLoader({ state: true, message: "Modifying Cases..." });
        if (data?.tableData?.checked) {
          await CaseService.selectCaseItem(data?.id);
        } else {
          await CaseService.removeCaseItem(data.id);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        // MTRef.current.onQueryChange("");
      }
    }
  };

  const deleteWaring = (isSelectAll, id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () =>
        isSelectAll ? deleteSelectedDraft() : deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case(s)?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        textAlign: "center",
      },
    });
  };

  const submitCaseWarning = () => {
    setOpen(true);
    setDialogData({
      secondaryBtnText: "Cancel",
      primaryBtnText: "Proceed",
      clickPrimaryBtn: () => selectAllSubmit(),
      clickSecondarybtn: () => setOpen(false),
      desc: `Before submitting the case, please check all the information that you have provided. The proceedings shall be conducted based on this information.`,
      //desc: `Before submitting a case, please make sure that all the mandatory fields are entered for every case. In case of any data is missed, it might lead to data issue and the process cannot be undone.`,
      heading: "Submit Cases",
      descriptionTextStyle: {
        textAlign: "center",
      },
    });
  };

  const selectAllSubmit = async () => {
    try {
      setLoader({ state: true, message: "Submitting Cases..." });
      const param = `?resolutionKind=${caseType}`;
      const res = await CaseService.submitDraftCase(param);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setTriggerCount(true);
      setOpen(false);
      setDialogData({});
      MTRef.current.onQueryChange("");
    }
  };

  const classes = useStyles();
  return (
    <Container>
      {columns.length > 0 && (
        <CustomTable
          onSelectionChange={handleCaseSelection}
          selection={draftSelection && selectedFilter === "Draft"}
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle={labels["cases"]}
          singularTitle={
            noParty
              ? labels["party"].toLowerCase()
              : labels["case"].toLowerCase()
          }
          customFilter={partyId ? false : true}
          onFilterClick={() => setModal(true)}
          filterCount={advanceFilter?.parties?.length}
          customMessage={noParty ? labels["table.caseError"] : null}
          {...{
            columns,
            data,
            filters,
            selectedFilter,
            setSelectedFilter,
            placeholderText,
            MTRef,
            page,
            setPage,
          }}
          state={state}
          left={isBigScreen ? true : false}
          top={isBigScreen ? "140px" : "-50px"}
        />
      )}

      <Footer>
        <ButtonContainer>
          <div
            className="button-row"
            style={{
              display:
                selectedFilter === "Draft" &&
                state?.data?.some((el) => el.tableData?.checked)
                  ? "flex"
                  : "none",
            }}
          >
            <DangerBtn
              style={{
                width: "216px",
                marginLeft: "26px",
                marginTop: 20,
              }}
              onClick={() => deleteWaring(true)}
            >
              {labels["table.delete"]}
            </DangerBtn>

            <PrimaryCTAButton
              style={{
                width: "216px",
                marginLeft: "26px",
                marginTop: 20,
              }}
              onClick={() => submitCaseWarning()}
            >
              {labels.proceed}
            </PrimaryCTAButton>
          </div>
        </ButtonContainer>
      </Footer>
      <Drawer
        variant="persistent"
        className={classes.root}
        anchor={"right"}
        open={modal}
        onClose={() => setModal(false)}
      >
        <FilterModal
          {...{
            modal,
            setModal,
            advanceFilter,
            setAdvanceFilter,
            partyRoles,
          }}
        />
      </Drawer>
      <AlertDialog isOpen={open} {...{ ...dialogData }} />
    </Container>
  );
};

export default CasesTable;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  & .button-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    @media ${theme?.breakpoints?.sm_up} {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
`;

const Container = styled.div``;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}
`;

const Datecolumn = styled.div`
  .delete {
    margin-top: 2px;
    cursor: pointer;
    color: ${COLORS.LOGOUT_RED};
    font-size: 12px;
  }
`;

const Bold = styled.span`
  cursor: pointer;
  color: ${({ editFlag }) => editFlag && COLORS.BOX_DARKMAGENTA};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}
  @media ${theme?.breakpoints?.sm_up} {
    font-family: ${theme.fonts.primaryFontBold};
  }
`;

const TextAlign = styled.span`
  @media ${theme?.breakpoints?.sm_up} {
    // font-family: ${theme.fonts.primaryFontBold};
  }
`;

const Center = styled.div`
  margin-left: 0;
  @media ${theme?.breakpoints?.lg_up} {
    margin-left: 35px;
  }
`;
