import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function Regulations(props) {
  return (
    <>
      <div>
        <Header />
        <H2 style={{ marginTop: "20px" }}>Regulations</H2>
        <RuleContainer>
          <HeadingOne>INDIA INTERNATIONAL ARBITRATION CENTRE</HeadingOne>
          <LineBottom />
          <Content>
            <div>
              <b>F. No. A-60011/96/2023-Administration(AR)-IIAC.</b> -In
              exercise of the powers conferred by sub-section (2) of section 31
              of the India International Arbitration Centre Act, 2019 (17
              of2019), the India International Arbitration Centre with the
              previous approval of the Central Government, hereby makes the
              following regulations to provide for the conduct of arbitration,
              namely: -
            </div>
            <LineBottom />
            <div>
              <CustomList>
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "10px" }}>
                    <b>1</b>
                  </p>
                  <p>
                    <b>Short title and commencement. - (I)</b> These regulations
                    may be called the India International Arbitration Centre
                    (Conduct of Arbitration) Regulations, 2023.
                    <br />
                    <span style={{ marginLeft: "25px" }}>
                      (2) They shall come into force on the date of their
                      publication in the Official Gazette
                    </span>
                  </p>
                </div>
              </CustomList>
              <LineBottom />
              <CustomList>
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "10px" }}>
                    <b>2</b>
                  </p>
                  <p>
                    <b>Definitions. - (I)</b> In these regulations, unless the
                    context otherwise requires, -
                  </p>
                </div>
              </CustomList>
              <LineBottom />
            </div>
            <CustomList
              type="a"
              style={{ paddingLeft: "20px" }}
              className="custom-list"
            >
              <Padding>
                "Act" means the India International Arbitration Centre Act, 2019
                (17 of2019);
              </Padding>
              <LineBottom />
              <Padding>
                "advisory panel" means a panel constituted by the Centre under
                regulation 9 for the purpose of advising the Chairperson in
                relation to the appointment of arbitrator, other than an
                emergency arbitrator;
              </Padding>
              <LineBottom />
              <Padding>
                "administration fee" means the administration fee specified in
                the Schedule;
              </Padding>
              <LineBottom />
              <Padding>
                Arbitral Tribunal" includes a sole arbitrator or all the
                arbitrators where more than one arbitrator is appointed;
              </Padding>
              <LineBottom />
              <Padding>
                "arbitrator's fee" means the arbitrator's fee specified in the
                Schedule;
              </Padding>
              <LineBottom />
              <Padding>
                "Arbitration Act" means the Arbitration and Conciliation Act,
                1996 (26 of 1996);
              </Padding>
              <LineBottom />
              <Padding>
                "award" includes an interim, partial , final or additional
                award;
              </Padding>
              <LineBottom />
              <Padding>
                "Centre" means the India International Arbitration Centre,
                established and incorporated under section 3 of the Act;
              </Padding>
              <LineBottom />
              <Padding>
                (i) "Chairperson" means the Chairperson of the Centre referred
                to in clau se (a) of sec tion 5 o f the Act;
              </Padding>
              <LineBottom />
              <Padding>
                "Chamber of Arbitration" means the Chamber of Arb itrati on as
                defin ed under secti on 28 oflhc Act;
              </Padding>
              <LineBottom />
              <Padding>
                "Chi ef Executi ve Officer" means the Chief Execut ive Officer
                appointed und er secti on 21 of the Act;
              </Padding>
              <LineBottom />
              <Padding>
                "emergency arbitrator" means an arbitra tor appoi nted pursuant
                lo r eg u I a t i o n 18;
              </Padding>
              <LineBottom />
              <Padding>
                "filing fee" means the filing fee specified in the Schedul e;
              </Padding>
              <LineBottom />
              <Padding>
                "Member" means a member of the Centre referred to in section 5 o
                f the Act;
              </Padding>
              <LineBottom />
              <Padding>
                "panel of arbitrators" means the panel of arbitrators mai
                ntained by the Centre
              </Padding>
              <LineBottom />
              <Padding>
                "practice directions" means the guidelines published by the
                Centre from time to lime, to implement these regulations;
              </Padding>
              <LineBottom />
              <Padding>
                "Registrar" means the registrar of the Centre or any other
                person cntrnstcd with the duties o ft hc registrar;
              </Padding>
              <LineBottom />
              <Padding>
                "Schedule" means the Schedule appended to these regulations;
              </Padding>
              <LineBottom />
            </CustomList>
            <div className="session-container" style={{ display: "flex" }}>
              <p className="session-no" style={{ marginRight: "10px" }}>
                (2)
              </p>
              <p className="session-text">
                The words and expressions used herein and not defined but
                defined in the Act or in the Arbitration Act shall have the
                meanings respectively assigned to them in those Acts.
              </p>
            </div>
            <LineBottom />
            <div>
              <CustomList>
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "10px" }}>
                    <b>3</b>
                  </p>
                  <p>
                    <b>Application. - </b> Where parties have agreed to refer
                    their dispute to the Centre for arbitration (whether before
                    or after a dispute has arisen) or where any Court directs
                    that the arbitration be conducted between the parti es under
                    the aegis of the Centre, the parties shall be deemed to have
                    agreed that the arbitration is to be conducted or
                    administered by the Centre in accordance with the provisions
                    of these regulations.
                  </p>
                </div>
              </CustomList>
              <LineBottom />
              <CustomList>
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "10px" }}>
                    <b>4</b>
                  </p>
                  <p>
                    <b>Request for arbitration. -</b> (I) An arbitration
                    proceeding under these regulations shall commence, -
                  </p>
                </div>
              </CustomList>
              <LineBottom />
              <CustomList
                type="a"
                style={{ paddingLeft: "35px" }}
                className="custom-list"
              >
                <Padding>
                  when a party makes a request, preferably by uploading it on
                  the portal provided on the website of the Centre
                  (www.indiaiac.org), or by way of an e-mail to the Registrar,
                  or by filing the physical copy of the request with the Centre;
                  or
                </Padding>
                <LineBottom />
                <Padding>
                  on receipt of a judgment, direction or order of any Court
                  referring the parties to the Centre for arbitration.
                </Padding>
              </CustomList>
            </div>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  When a party makes a request for arbitration with the Centre
                  (hereinafter referred to as the claimant), the request must
                  contain or be accompanied by, -
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "35px" }}
              className="custom-list"
            >
              <Padding>
                the order of the Court referring the parties to arbitration, in
                cases covered by clause (b) of sub-regulation (I);
              </Padding>
              <LineBottom />
              <Padding>
                the complete arbitration clause or the separate arbitration
                agreement where the parties have agreed for conduct of
                arbitration by or under the Centre;
              </Padding>
              <LineBottom />
              <Padding>
                a reference to and, where possible, a copy of the contract or
                other instruments out of, or in relation to which, the dispute
                has arisen;
              </Padding>
              <LineBottom />
              <Padding>
                the full name and postal address including telephone number,
                mobile number and electronic mail address, to the extent known,
                of the parties to the arbitration and their legal representative
                and successor in interest, if any;
              </Padding>
              <LineBottom />
              <Padding>
                a statement briefly describing the nature and circumstances of
                the dispute and the claims made against other party to the
                arbitration (hereinafter referred to as the responde_nt),
                specifying the relief sought (including the amounts of any
                quantified claims and, to the extent possible, an estimate of
                the monetary value of any such claims);
              </Padding>
              <LineBottom />
              <Padding>
                a statement of any matters which the parties have previously
                agreed with regard to eonductofthe arbitration or with respect
                to which the claimant desires lo make a proposal as regard to
                the number of arbitrators governing law of the contract,
                governing law of arbitration,the language of the arbitration and
                place/sea; of arbitration;
              </Padding>
              <LineBottom />
              <Padding>
                the nomination of an ~rbitrawr, if the. arbitration agreement
                prnvides for three arbitrators, or a proposal nommatrng a sole
                arbitrator 1f the arb1trat10n agreement provides for a
                solearbitrator, unless the parties have agrccd _othcrwisc
                including the power of n omination and _appointment, as the case
                may be, being conforrcd wnh the Chairperson or the Centre 111
                accordance with these regulations or except where an arbitrator
                has been named by a Court referring the parties for arbitration;
              </Padding>
              <LineBottom />
              <Padding>
                confirmation that copies of the request fo r arbitration and any
                documents, have been or are being served sunultancously on all
                other parties, specifying the mode of service employed and the
                date of service, to 3 be supported then or as soon as possible
                thereafter by documenta ry proof of actua l deli very including
                the date of delivery; and
              </Padding>
              <LineBottom />
              <Padding>
                confinnation that the requisite filing fee as speci fi ed in the
                Schedule has been paid
              </Padding>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The request for arbitration shall not prevent any party fro m
                  subsequentl y adding, supplementing or amending in its
                  pleadings, the matters referred to arbitration or the reliefs
                  claimed, prov ided such matters and rel iefs fa ll within the
                  scope of the arbitration agreement and arc relevant to the
                  dispute
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  mmencement of the arbitration and the request fo r arbitration
                  shall be deemed to be complete when all the requirements of
                  sub-regulation (2) arc fulfill ed or when the Centre determin
                  es that there has been substanti al compliance with such
                  requirements and notifies the parties of the date of
                  commencement of the arbitration
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>5</b>
                </p>
                <p>
                  <b>Response to the request for arbitration.</b>- (I) The
                  respondent shall send to the claimant and to the Centre a
                  response within fourteen days of the receipt of the request
                  for arbitration, preferably by uploading it on the portal
                  provided on the website of the Centre (www.i11diaiac.org) or
                  by way ofan e-mail, or by fi ling the physica l copy of the
                  response to the request for arbitration with the Centre, which
                  shall contain or be accompanied by
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "35px" }}
              className="custom-list"
            >
              <Padding>
                a confirn1ation or denial of all or part of the claims, or the
                claimant's invocation ofthc arbitration;
              </Padding>
              <LineBottom />
              <Padding>
                the full name and postal address along with telephone number,
                mobile number and e-mail ID of the respondent and its legal
                representative and successor in interest, if any;
              </Padding>
              <LineBottom />
              <Padding>
                a statement describing in brief the nature and circumstances of
                the dispute and the defence to the claim, and a brief statement
                describing the nature and circumstances of any counter-claims,
                specifying the relief claimed, including the amounts of any
                quantified counter-claims and, to the extent possible, an
                estimate of the monetary value of any other counter-claims;
              </Padding>
              <LineBottom />
              <Padding>
                respondent desires to make a proposal, on matters relating to
                the conduct of the arbitration as regard to the number of
                arbitrators, governing law of the contract, governing law of the
                arbitration, the language of the arbitration and the place/seat
                of arbitration;
              </Padding>
              <LineBottom />
              <Padding>
                where the arbitration agreement provides for a sole arbitrator,
                concurrence with the claimant's proposal for a sole arbitrator
                or otherwise; or where the arbitration agreement provides for
                three arbitrators, the nomination of arbitrator as envisaged in
                such agreement, unless the parties have agreed otherwise
                including the power of nomination and appointment, as the case
                may be, being conferred with the Chairperson or the Centre in
                accordance with these regulations or except where one or more
                arbitrators have been named by a Court;
              </Padding>
              <LineBottom />
              <Padding>
                confirmation that copies of the response and the documents
                relied on have been or arc being served simultaneously on the
                other parties, specifying the mode of service employed and the
                date of service, by documentary proof of actual delivery
                including the date of delivery;
              </Padding>
              <LineBottom />
              <Padding>
                confirmation that the requisite filing fee as specified in the
                Schedule has been paid for any counter-claim.
              </Padding>

              <LineBottom />
            </CustomList>
            <div style={{ display: "flex", margin: "0 17px" }}>
              <p className="session-no" style={{ marginRight: "10px" }}>
                (2)
              </p>
              <p>
                The contents of the response shall not prevent the respondent
                from subsequently adding, supplementing or amending its
                pleadings.
              </p>
            </div>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>6</b>
                </p>
                <p>
                  <b>Written communication and calculation of period.</b>- (1)
                  Any notice or communication or proposal shall be in writing,
                  or by way of electronic communication, hereinafter referred to
                  as the written communication and such written communication
                  may be delivered personally or by registered post or by a
                  courier service, or transmitted by any form of electronic
                  means or delivered by any other means that provides a record
                  of its transmission or in any other maruier as may be directed
                  by the Centre or the Arbitral Tribunal and such written
                  communication shall be deemed to have been received if it is
                  delivered,-
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "35px" }}
              className="custom-list"
            >
              <Padding>to the addressee personally; or</Padding>
              <LineBottom />
              <Padding>
                to his habitual residence, place of business or designated
                address; or
              </Padding>
              <LineBottom />
              <Padding>
                to any address agreed by the parties for service of
                communication; or
              </Padding>
              <LineBottom />
              <Padding>
                according to the practice of the parties in prior dealings; or
              </Padding>
              <LineBottom />
              <Padding>to his known e-mail address; or</Padding>
              <LineBottom />
              <Padding>
                if none of the aforesaid can be found alter making reasonable
                inquiry, then at the addressee's last-known place of business or
                residence.
              </Padding>
              <LineBottom />
            </CustomList>
            <div>
              <b>Explnation.</b> - In this regulation, "electronic
              communication" includes electronic mail and any other type of
              communication which provides a record of transmission.
            </div>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Any written communication shall be deemed to have been
                  received on the day when it is delivered or, in the case
                  ofclcctro111 c means, transmiltcd, and such time shall be
                  determined wit h reference to th e time zone of the place/scat
                  of arbitration and_ in the event no place/scat of arbitration
                  has been chosen by the parties or determined by the Trbitral
                  Tribunal, the tnnc ofrcec,pt shall be constructed based on
                  Indian Standard Time.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  Any. period under these regulations shall be calculated from
                  the day following the day when a written communication 1s
                  deemed to have been received under sub-regu lation (2).
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <div>
              <b>Explnation.</b> When the day next fo llowing such date is a
              non-busincssday in the place of receipt of such wrillen commu111
              cat1on pursuant lo sub-regulation (I), the period commences on the
              firstfoll owing business day and if the last day_ of such period
              is a non-business day at the place of such receipt,the period is
              extended until the fast business day whi ch follows.
            </div>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  Non-business days occurring during the mnning of the period
                  arc included in calculating the overal l period
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  After the constitution of the Arbitral Tribunal, where any
                  party delivers any written communication to the Arbitral 1
                  nbunal, it shall simultaneously deliver a copy to each
                  arbitrator, all parties and the Registrar and it shall confirm
                  in wntmg to the Arbitral Tribunal that it has done so or is
                  doing so.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  The Registrar may, if the parties so request or the
                  circumstances so warrant, amend the time limits prescribed in
                  thc~c regulations provided it docs not contravene any
                  provisions of the law applicable to the arbitration or any
                  time hm1ts as fixed by the Arbitral Tribunal.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>7</b>
                </p>
                <p>
                  <b>Joindcr of additional parties.</b>- (I) After the
                  constitution of the Arbitral Tribunal, a party to the
                  arbitration may apply to the Arbitral Tribunal to join one or
                  more additional parties in a arbitral proceeding pending
                  before it.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The arbitral tribunal may allow the application after
                  satisfaction of the following conditions, namcly:-
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "60px" }}
              className="custom-list"
            >
              <Padding>
                the parties, including the additional party to be joined, have
                consented to the joindcr of the additional party; or
              </Padding>
              <LineBottom />
              <Padding>
                the additional party to be joined is prim a fade bound by the
                arbitration agreement;
              </Padding>
              <LineBottom />
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>An application for joinder shall includc,-</p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "60px" }}
              className="custom-list"
            >
              <Padding>
                a brief statement of the facts and reasons supporting the
                application.
              </Padding>
              <LineBottom />
              <Padding>
                the details of the pending arbitration in which one or more
                additional parties arc sought to be joined;
              </Padding>
              <LineBottom />
              <Padding>
                the name and address along with telephone numbers and e-mail ID,
                of the additional party to be joined, and their representatives,
                if any;
              </Padding>
              <LineBottom />
              <Padding>
                whether the additional party is to be joined as a claimant or a
                respondent;
              </Padding>
              <LineBottom />
              <Padding>
                identification of the relevant arbitration agreement and a copy
                of such agreement;
              </Padding>
              <LineBottom />
              <Padding>
                a reference to the contract or other instrument out of or in
                relation to which the dispute arises and, where possible, a copy
                of the contract or other instrument.
              </Padding>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  {" "}
                  The Arbitral Tribunal shall, after giving the parties,
                  including the additional party to be joincd,an opportunity to
                  be heard, and having regard to the circumstances of the case,
                  decide whether to allow, in whole or in part, any application
                  for joindcr under sub-regulation (I).{" "}
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  {" "}
                  The decision of the Arbitral Tribunal to allow the application
                  for joindcr under this regulation shall be without prejudice
                  to its power to subsequently decide any question as to its
                  jurisdiction for such decision
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  {" "}
                  Where an application for joindcr is allowed under
                  sub-regulation (4), the filing fee under these regulations
                  shall be payable for any additional claims or countcr-cla1ms.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>8</b>
                </p>
                <p>
                  <b>Consolidation of arbitrations. </b>- (I) Prior to the
                  constitution of the arbitral tribunal in the arbitration
                  sought to be consolidated, at the request of a party for
                  consolidation, and after notice to the other parties and any
                  Arbitral Tribunal, if constituted in the arbitration that
                  commenced first, the Chairperson may allow to consolidate two
                  or more arbitrations into a common arbitration where-
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "60px" }}
              className="custom-list"
            >
              <Padding>the parties agree to the consolidation; or</Padding>
              <LineBottom />
              <Padding>
                the claims in the arbitrations arc made under the same
                arbitration agreement; or
              </Padding>
              <LineBottom />
              <Padding>
                the disputes or differences therein arc identical and between
                the same parties or between the parties having commonality of
                interests, or where such disputes arise out of separate
                contracts but relate to the same transaction.
              </Padding>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>2</p>
                <p>
                  The party making the request for consolidation shall provide
                  copies of such request to all other parties and to the
                  Arbitral Tribunal, if any, in the arbitration that commenced
                  first.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>3</p>
                <p>
                  In deciding whether to consolidate, the Chairperson shall take
                  into account all the circ umstances of the case and he shall
                  endeavor to decide the application for consolidati on within a
                  period of fourteen days of the receipt of the request for
                  consolidation.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>4</p>
                <p>
                  Where the Chairperson decides to consolidate two or more
                  arbitrations under sub-regulation (I), the arbitrations shall
                  be consolidated into the arbitration that commenced first,
                  unless the Chairperson decides otherwise, taking into account
                  the circumstances of the case.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>5</p>
                <p>
                  The Registrar shall provide copies of the decision of the
                  Chairperson to all parties and to the Arbitra l Tribunal
                  constituted, if any, in the arbitration that commenced first.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>6</p>
                <p>
                  The Centre may suitably adjust its administration fe e and the
                  Arbitral Tribunal's fee (where appropriate) after a decision
                  to consolidate the arbitrations has been made.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>9</b>
                </p>
                <p>
                  <b>Composition and functions of the advisory panel. </b>- (I)
                  The advisory panel constituted by the Centre sha ll consist of
                  the Members of the Centre, other than Members ex officio, and
                  other eminent indi viduals havi ng wide experience in the area
                  of alternative dispute resolution mechanisms including
                  arbitration, at domestic or international level.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The advisory panel may consist of such numbcrofadvisors as the
                  Centre may decide from time to time, which shall not be less
                  than three but not more than ten.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex", marginLeft: "25px" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  Not less than two advisors of the advisory panel shall advise
                  the Chairperson in respect of appointment ofan arbitrator
                  either from the panel of arbitrators maintained by the Centre,
                  or any other arbitrator in exceptional circumstances.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>10</b>
                </p>
                <p>
                  <b>
                    Disclosure on independence and impartiality of the
                    arbitrators.
                  </b>
                  - (I) Where a person is to be appointed as an arbitrator, he
                  shall disclose in writing-
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList
              type="a"
              style={{ paddingLeft: "60px" }}
              className="custom-list"
            >
              <Padding>
                the existence, either direct or indirect, of any past or
                present, relationship with or interest in any of the parties or
                in relation to the subject-matter in dispute, whether financial,
                business, professional or of any other kind, which is likely to
                give rise to justifiable doubts as to his independence or
                impartiality; and{" "}
              </Padding>
              <LineBottom />
              <Padding>
                which arc likely to affect his ability to devote sufficient time
                to the arbitration and in particular his ability to complete the
                entire arbitration within the specified time.
              </Padding>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "20px" }}>(2)</p>
                <p>
                  An arbitrator, from his appointment and throughout the
                  arbitral proceedings, shall,without delay, disclose to the
                  parties in writing any circumstances referred to in
                  sub-regulation (1), unless they have already been informed of
                  them by him.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>11</b>
                </p>
                <p>
                  <b>Appointment and confirmation of arbitrators. </b>- (1)
                  Unless the parties have agreed otherwise, a sole arbitrator
                  shall be appointed.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Subject to the agreement between the parties, the parties may
                  nominate an arbitrator from the panel of arbitrators
                  maintained by the Centre or in exceptional circumstances, any
                  other arbitrator.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  If the parties have agreed that any arbitrator is to be
                  appointed by one or more of the parties, or by any third
                  person or by the arbitrators already appointed, such agreement
                  shall be treated as an agreement to nominate an arbitrator
                  under these regulations and such nominations shall be subject
                  to appointment by the Chairperson.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  The Chairperson shall appoint arbitrators including from the
                  panel of arbitrators maintained by the Centre after
                  considering the advice of the advisory panel.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  In appointing an arbitrator under these regulations, due
                  consideration shall be given to the qualifications required
                  for the arbitrator as per the agreement of the parties, and to
                  such considerations as arc likely to secure the appointment of
                  an appropriate arbitrator, who is independent and impartial,
                  has sufficient time, availability and ability to conduct the
                  case in a prompt and efficient manner.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  No claimant or respondent or any person shall make any
                  ex-parte or private communication relating to the case with
                  any arbitrator after he is appointed.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>12</b>
                </p>
                <p>
                  <b>Arbitral tribunal comprising of Sole Arbitrator. </b>- (I)
                  If a sole arbitrator is to be appointed, either of the parties
                  may propose to the other party the names of one or more
                  persons to be appointed as the sole arbitrator and after
                  reaching consensus on the nomination of a sole arbitrator, the
                  Chairperson may appoint such nominated person as sole
                  arbitrator.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Jf the parties have not reached consensus for the nomination
                  of a sole arbitrator within twenty-eight days from the date of
                  commencement of the arbitration, the Chairperson, on the
                  request of either party, shall appoint the sole arbitrator
                  after considering the advice of'the advisory panel.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>13</b>
                </p>
                <p>
                  <b>Arbitral tribunal comprislng of Three arbitrators. </b>-
                  (I) If three arbitrators arc to be appointed, each party shall
                  nominate one arbitrator.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "42px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  If a party fails to nominate an arbitrator within fourteen
                  days aflcr rece ipt of a party's nomination of an arbitrator,
                  the Clrnirpcrson shall proceed to appoint an arbitrator on its
                  behalf aflcr considering the advice of the advisory panel.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  Unless the parties have ngrccd upon another procedure for
                  appointing the 1h ird arbitrator, or if such agreed procedure
                  docs not result in a nomination within the period agreed by
                  the parties or se t by the Chairperson, the Chairperson shall
                  appoint the th ird arbitrator after considering the advice of
                  the advisory panel, who shall be the presiding arbitrator.
                </p>
              </div>
              <LineBottom />
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>14</b>
                </p>
                <p>
                  <b> Multi-party appointment of arbitrator. </b>- (I) Where
                  there arc more than two parties to the arbitration, and a sole
                  arbitrator is to be appointed, the parties may jointly
                  nominate the sole arbitrator and in the absence of such joi nt
                  nomination having been made within twenty-eight days of the
                  date of commencement of the arbitration, the Chairperson shall
                  appoint the sole arbitrator after considering the advice of
                  the advisory panel
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Where there arc more than two parties to the arbitration, and
                  three arbitrators arc to be appointed, the claimants shall
                  jointly nominate one arbitrator and the respondents shall
                  jointly nominate one arbitrator and unless the parties have
                  agreed upon another procedure for appointing thcthird
                  arbitrator, or if such agreed procedure docs not result in a
                  nomination within the period agreed by the parties or set by
                  the Chairperson, the Chairperson shall appoint the third
                  arbitrator on the advice of the advisory panel, who shall be
                  the presiding arbitrator.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  In the absence of both such joint nominations having been made
                  within twenty-eight days from the date of commencement of the
                  arbitration, the Chairperson shall appoint all three
                  arbitrators on the advice of the advisory panel and shall
                  designate one of them to be the presiding arbitrator.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>15</b>
                </p>
                <p>
                  <b>Challenge of arbitrators. </b>- (I) The appointment or
                  continuation of a person as an arbitrator may be challenged by
                  any party if circumstances exist that give rise to justifiable
                  doubts as to the arbitrator's impartiality or independence, or
                  ifthcarbitrator docs not possess any requisite qualification
                  which the parties have previously agrccd,or if the arbitrator
                  becomes unable to discharge his functions or is not
                  discharging those functions in accordance with these
                  regulations or within the specified period.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList style={{ paddingLeft: "45px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  A party may challenge the arbitrator nominated by him only for
                  reasons of which he becomes aware after such nomination has
                  been made.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  A party who intends to challenge the appointment of an
                  arbitrator shall send a notice to the Registrar within
                  fourteen days after the receipt of the notice of appointment
                  of such arbitrator, or within fourteen days after the
                  circumstances mentioned in sub-regulation (I) become known to
                  that party.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  The notice of challenge shall be sent simultaneously to the
                  other party, the arbitrator who is being challenged and the
                  other members of the Arbitral Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  The notice of challenge shall be in writing and shall state
                  the grounds for the challenge specified in subregulation (I).
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  The Registrar may, by order suspend the arbitration
                  proceedings until thcchallcngc is resolved.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(7)</p>
                <p>
                  When an arbitrator is challenged by one party, and the other
                  party docs not dispute the challenge made by the first party
                  within seven days of the date of receipt of the notice of
                  challenge, the Chairperson may replace such arbitrator and the
                  arbitrator so challenged may also withdraw voluntarily from
                  his office.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(8)</p>
                <p>
                  In neither case referred to in sub regulation (7), does this
                  imply acceptance of the validity of any of the grounds for the
                  challenge.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(9)</p>
                <p>
                  In instances referred to in sub-regulation (7), a substitute
                  arbitrator shall be appointed in accordance with the procedure
                  referred to in regulation I 6.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(10)</p>
                <p>
                  If the other party docs not agree to the challenge and the
                  arbitrator, who is being challenged docs not withdraw himself
                  voluntarily, the Chairperson shall send a copy of the notice
                  to the parties and to the arbitrator to make submissions, or
                  as the case may be, comments on the challenge within such
                  period as may be provided by the Chairperson.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(11)</p>
                <p>
                  The Chairperson shall endeavour to decide on the challenge
                  within fifteen days from the date of submissions of the
                  parties or the comments of the arbitrator, whichever is later.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(12)</p>
                <p>
                  If the Chairperson accepts the notice of challenge, a
                  substitute arbitrator shall be appointed inaccordancc with
                  these regulations.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(13)</p>
                <p>
                  If the Chairperson rejects notice of the challenge, the
                  arbitrator shall continue with the arbitration.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>16</b>
                </p>
                <p>
                  <b>Substitution of arbitrator. </b>- (I) An arbitrator shall
                  be substituted upon the fo llowing conditions:
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  refusal or failure lo act in accordance with these regu
                  lations or withi n spec ified period; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>
                  unable to pcrfonn the function s in accordance with these
                  regulations; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(c)</p>
                <p>
                  in case of his death, resignation, or withdrawa l from the
                  arbitration; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(d)</p>
                <p>
                  acceptance of a challenge of arbitrator under regulation 15 by
                  the Chairperson ; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(d)</p>
                <p>
                  in case of written request made by all the parties for the
                  removal of the arbi trator lo the Chairperson and where such
                  request has been accepted by him.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  When an arbitrator is lo be substituted for any reason. a
                  substitute arbitrator shall be appointed by the Chairperson as
                  per the procedure specified for the appointment of the
                  arbitrator under these regulations.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  On substitution of an arbitrator, the rcconslilulcd Arbitral
                  Tribunal may invite and hear the parties lo determine as lo
                  whether and lo what extent the proceedings that have already
                  taken place, shall be repeated or otherwise before the
                  reconstituted Arbitral Tribunal:
                </p>
              </div>
              <LineBottom />
              <div>
                Provided that if the previous Arbilral Tribunal has made an
                interim or partial award, any hearings related lo such award,
                shall nol be held again by the reconstituted Arbitral Tribunal
                and such interim or partial award shall remain effective.
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>17</b>
                </p>
                <p>
                  <b>Fast track procedure. </b> - (I) The parties lo an
                  arbitration agreement may mutually agree in writing to have
                  their dispute resolved through a fast track procedure at any
                  stage, either before or at the time of appointment of the
                  Arbitral Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Pursuant to such agreement, the parties may apply to the
                  Registrar in writing for the arbitral proceedings to be
                  conducted in accordance with the Fast Track Proce
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The parties making an application under sub-regulation (2)
                  shall send a copy of the application to the other parties and
                  to the Registrar.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  Where a party has made an application under sub-regulation
                  (2), and where the Chairperson decides, after considering the
                  views of the parties and having regard to the circumstances of
                  the case, that the arbitral proceedings shall be conducted in
                  accordance with the fast track procedure, the following
                  procedure shall apply, notwithstanding anything contrary to
                  the arbitration agreement between the parties, namcly:-
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  the case shall be referred to a sole arbitrator, unless the
                  Chairperson decides otherwise;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>
                  the Registrar may reduce any time period under these
                  regulations;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(c)</p>
                <p>
                  the Arbitral Tribunal shall decide the dispute on the basis of
                  written pleadings, documents and submissions filed, unless
                  agreed otherwise by the parties;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(d)</p>
                <p>
                  the Arbitral Tribunal shall have the power to call for any
                  further information or clarification from the parties in
                  addition to the pleadings and documents filed by them;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(e)</p>
                <p>
                  an oral hearing may be held only if all the parties make a
                  request or if the Arbitral Tribunal considers it necessary to
                  have an oral hearing for clarifying certain issues;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(f)</p>
                <p>
                  the Arbitral Tribunal may dispense with any technical
                  formalities, if an oral hearing is held, and adopt such
                  procedure, as deemed appropriate for expeditious disposal of
                  the case;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(g)</p>
                <p>
                  the award under this regulation shall be made within a period
                  of six months from the date of intimation by the Registrar to
                  the parties, of the constitution of the Arbitral Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(h)</p>
                <p>
                  if the award is not made within the said period of six months,
                  the mandate of the Arbitral Tribunal shall terminate unless
                  the period to make the award has been extended by the
                  Registrar for reasons to be recorded in writing.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  Upon application by a party, the Arbitral Tribunal may, after
                  giving the parties the opportunity to be heard and in
                  consultation with the Registrar, order that the arbitral
                  proceedings shall not be conducted in accordance with the fast
                  track procedure under this regulation, and where the Arbitral
                  Tribunal allows such application, the arbitration shall
                  continue to be conducted by the same Arbitral Tribunal that
                  was constituted to conduct the arbitration in accordance with
                  the fast track procedure.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>18</b>
                </p>
                <p>
                  <b> Emergency arbitrator. </b>- (I) Where an application for
                  emergency interim relief cannot wait till the Arbitral
                  Tribunal is constituted, a party may apply to the Registrar in
                  writing for such emergency interim relief.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The application under sub-regulation (I) may be made al the
                  lime of filing or subsequent to the filing of the request for
                  arbitration but prior lo the constitution of the Arbitral
                  Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The party making an application under sub-regul ation ( I)
                  shal l simultaneously send a copythcrco f lo the other parties
                  to the arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>The party making such an application shall -</p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  include a statement briefly describing the 1rnturc and
                  circumstances of the relief sought and specific reasons why
                  such relief is required on an emergency basis and the reasons
                  why the pnrtyis entitled to such relief;{" "}
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>
                  pay the applicable fee as specified in the Schedule for the
                  appointment of the emergency arbitrator; and
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(c)</p>
                <p>
                  fil e proof of service of such application upon the other
                  parties to the arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  The Chairperson shall endeavor to appoint the emergency
                  arbitrator within three days (including non- business days) of
                  receipt of the application by the Registrar.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  The emergency arbitrator to be appointed under sub-regulation
                  (5) must disc lose 10 the Registrar any facts or circmnstanccs
                  which may give rise to justifiable doubts as to his
                  impartiality or independence, before accepting the
                  appointment.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(7)</p>
                <p>
                  Any challenge to the appointment of the emergency arbitrator
                  may be made by either of the parties within one business day
                  of the communication by the Registrar of the said appointment
                  and the application must stale the circumstances which fonn
                  the basis for the challenge.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(8)</p>
                <p>
                  An emergency arbitrator may not act as an arbitrator in any
                  future arbitration relating to the said dispute unless agreed
                  by all the parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(9)</p>
                <p>
                  The emergency arbitrator so appointed shall schedule a hearing
                  including the filing of submissions and documents by the
                  parties within two business days of his appointment and shall
                  provide a reasonable opportunity of being heard to all the
                  parties to the dispute before granting any urgent interim
                  measures of protection and shall record reasons in the order.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(10)</p>
                <p>
                  The parties shall comply with any order made by the emergency
                  arbitrator.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(11)</p>
                <p>
                  The emergency arbitrator shall have the power to order any
                  interim relief that he deems necessary.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(12)</p>
                <p>
                  An order of the emergency arbitrator shall be made in writing,
                  with a brief statement of reasons and shall be signed.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(13)</p>
                <p>
                  The emergency arbitrator shall ensure that the entire process
                  ofarbitration from date of his appointment to making of the
                  order shall be completed within fifteen days, which may only
                  be extended by the Registrar in exceptional circumstances or
                  by written agreement of all the parties to the said
                  proceedings.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(14)</p>
                <p>
                  The emergency arbitrator shall becomc/unc/us officio after the
                  order is made and shall not be a part of the Arbitral
                  Tribunal, which may be formed subsequently, in accordance with
                  these regulations unless otherwise agreed toby all the
                  parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(15)</p>
                <p>
                  The order for urgent interim measures made by the emergency
                  arbitrator shall not bind the Arbitral Tribunal on the merits
                  of any issue or dispute that the Arbitral Tribunal may be
                  required to determine.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(16)</p>
                <p>
                  The order made by the emergency arbitrator shall remain
                  operative for a period of two months from the date of the
                  order unless modified, substituted or vacated by the
                  ArbitralTribunal:
                </p>
              </div>
              <LineBottom />
              <div>
                Provided that the Arbitral Tribunal shall have the power to
                extend the said order beyond the period of two months if it
                deems it necessary for reasons to be recorded in writing.
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(17)</p>
                <p>
                  Any order of the emergency arbitrator may be con finned,
                  varied, discharged or revoked, in whole or in part, by order
                  or award made by the Arbitral Tribunal upon application by any
                  party or upon its own initiative.
                </p>
              </div>
            </CustomList>
            <LineBottom />
            <CustomList>
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>19</b>
                </p>
                <p>
                  <b>Interim measures by Arbitral Tribunal. </b>- (I) A party
                  may, during the arbitral proceedings apply to the Arbitral
                  Tribunal for an interim measure of protection in respect of
                  the subject-matter of the dispute as it may consider
                  necessary, including-
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  the appointment of a guardian for a minor or person of unsound
                  mind for the purposes of arbitral proceedings;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>
                  an interim measure of protection in respect of any of the
                  following matters, namely: -
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(i)</p>
                <p>
                  the preservation, interim custody or sale of any goods which
                  arc the subject matter of the arbitration;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(ii)</p>
                <p>securing the amount in dispute in the arbitration;</p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(iii)</p>
                <p>
                  the detention, preserva tion or inspection of any property or
                  thing which is the subject-matter of the dispute in
                  arbitration, or as to which any question may arise therein and
                  nuthorizing for any of the 9 aforesaid purposes any person to
                  enter upon any land or bui lding in the possession of any
                  ~arty or authorizing any sampl es to be taken, or any
                  observation to be made, or experiment to be tri ed, which may
                  be necessary or expedient for the purpose of obtaining fu ll
                  information or evidence;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(iv)</p>
                <p>interim injunction or the appointment of a receiver;</p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(v)</p>
                <p>
                  such other interim measures of protection as may appear to the
                  J\rbitral Tribu nal lo be just and convenient.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal may modify, suspend or terminate an
                  interim measure granted by it, uponan application by 3 party,
                  if the circumstances so warrant.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>20</b>
                </p>
                <p>
                  <b>Statement of claim. </b>- (I) The claimant shall, within
                  such period as may be determined by the J\rbitra l Tribuna l,
                  submit the statement of claim, preferably by uploading it on
                  the portal provided on the website of the Centre
                  (www.i11diaiac.org) or by way of an e-mail, or by filin g a
                  physical copy of the statement of claim with the J\rb1tral
                  Tribunal with a copy to the respondent and to the Centre,
                  setting out in full detail subj ect to any specific directions
                  of the Arbitral Tribunal in this regard-
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>statement of facts supporting the claim;</p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>copies of supporting documents; and</p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(c)</p>
                <p>
                  the relief claimed, together with the amount of all
                  quantifiable claims.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  If the claimant fails to submit its statement of claim with
                  the specified period, the J\rbitral Tribunal may issue an
                  order for the tcnnination of the arbitral proceedings or give
                  such other directions as may be appropriate, unless a
                  respondent has brought a counter claim and requests the
                  arbitration to continue.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>21</b>
                </p>
                <p>
                  <b>Statements of defence and counter claim. </b>- (I) The
                  respondent shall submit its statement of defence before the
                  Arbitral Tribunal within the period specified by the J\rbitral
                  Tribunal, preferably by uploading it on the portal provided on
                  the website of the Centre (www.i11diaiac.org) or by way ofan
                  c-_mail, or by filing a physical copy of the statement of
                  defence and furnish a copy to the claimant and to the Centre,
                  setting out its full defence to the statement of claim,
                  subject to any specific directions of the Arbitral Tribunal in
                  this regard.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The statement of defence may also include a counter claim (if
                  any), which shall comply withthc requirements of regulation
                  20.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  If a counter claim is made, the claimant shall within a
                  specified period decided by the Arbitral Tribunal, send to the
                  respondent a statement of defence to the counter claim (with a
                  copy to the J\rbitral Tribunal and the Centre), setting out
                  its full defence to the counter claim.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  If the respondent fails to submit a statement of defence, or,
                  if at any stage any party fails to avail itself of the
                  opportunity to present its case in the manner directed by the
                  Arbitral Tribunal, the Arbitral Tribunal may proceed with the
                  arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>22</b>
                </p>
                <p>
                  <b>Amendments to the statements of claim or defence. </b>-())
                  A party may, with the leave of the Arbitral Tribunal, amend,
                  supplement or modify its claim, counter-claim or other
                  pleadings, unless the Arbitral Tribunal considers it
                  inappropriate to allow such amendment, having regard to the
                  delay in making the request or the prejudice it might cause to
                  the other party or any other circumstance.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>23</b>
                </p>
                <p>
                  <b> Further pleadings. </b>- The Arbitral Tribunal shall
                  decide whether further pleadings shall be required from the
                  parties or may be presented by them and it shall fix the
                  periods of time for filing such pleadings, if any.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>24</b>
                </p>
                <p>
                  <b>Jurisdiction. </b>- (I) If a party objects to the existence
                  or validity of the arbitration agreement, or to the competence
                  of the Centre to administer an arbitration, the same shall be
                  decided by the Arbitral Tribunal after it is constituted.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal shall have the power to decide on its
                  own jurisdiction, including any objections with respect to the
                  existence, termination or validity of the arbitration
                  agreement.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  A pica that the Arbitral Tribunal docs not have jurisdiction
                  shall be raised not later than in a statement of defence or in
                  a statement of defence to the counter-claim and a failure by a
                  party to raise an objection on jurisdiction by then , shall be
                  treated as an express waiver of that objection.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>25</b>
                </p>
                <p>
                  <b>Conduct of procccdln_gs. </b>- (I ) The J\rbitral Tribunal
                  may conduct the arbitration in such manner as provided m the
                  law govern mg the arb1trat1011 and as 11 considers appropriate
                  to ensure the cost effective, fair and timely resolution of
                  the dispute.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  As soon as practicable after the appointment of all
                  arbitrators, the Arbitral Tribunal shall conduct a preliminary
                  meeting with the panies (in person, by audio/video
                  conferencing or any other electronic mode). to discuss the
                  procedures that will be most appropriate and cflicicnt for the
                  case.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  During or foll owing the preliminary meeti ng, the Arbitral
                  Tribunal shall establish the procedural time table that it
                  intends to follow for the conduct ofthc arbitration (including
                  the period within which parties slrnll lead ev idence and make
                  oral submissions on a day-to-day basis) and shall communicate
                  the procedural time table to the Centre and the panies.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  The Arbitral Tribunal after consulting the panics, may adopt
                  furth er procedural measures or modify theproccdural timetable
                  from time to time and all such modified procedural timetables
                  and orders shall be communicated to the parties and the
                  Centre, to ensure continued effective case management.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  The filing of the statement of claim and statement of defence
                  shall be completed within a maximum period of six months from
                  the date the arbitrator or all the arbitrators, as the case
                  may be, received notice in writing of their appomtmcnt.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  The Arbitral Tribunal may frame a list of issues after the
                  pleadings arc compl eted, if it dccmsit appropriate to do so.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(7)</p>
                <p>
                  The Arbitral Tribunal may proceed with the arbitration
                  notwithstanding the failure or refusal of any party to comply
                  with these regulations or orders or directions of the Arbitral
                  Tribunal or any partial or interim award or to attend any
                  meetings or hearings, and may impose such costs as the
                  Arbitral Tribunal deems appropriate in such circumstances.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>26</b>
                </p>
                <p>
                  <b>Language of the arbitration proceedings. </b>- ( 1) Unless
                  the parties have agreed otherwise, the language of the
                  arbitrationshall be English.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  If a document is in a language other than the language of the
                  arbitration, the Arbitral Tribunal, or if the Arbitral
                  Tribunal has not been established, the Registrar, may order a
                  party to submit translation of the document, in the language
                  of the arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>27</b>
                </p>
                <p>
                  <b>Party representatives. </b>- (I) Any party may be
                  represented by legal practitioners or any other authorised
                  representatives, subject to submission ofproofofauthority of
                  the party representatives.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Any change or addition by a party to its representatives shall
                  be promptly communicated in writing to the Registrar, the
                  Arbitral Tribunal and the other parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>28</b>
                </p>
                <p>
                  <b>Place/scat of arbitration. </b>- (1) The parties may agree
                  on the place/scat of arbitration, failing such an agreement,
                  the place/ seat of arbitration shall be determined by the
                  Arbitral Tribunal having regard to the circumstances of the
                  case.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal, in consultation with the parties may
                  hold all proceedings including hearings either physically,
                  vinually or in a hybrid manner and where the Arbitral Tribunal
                  decides to hold the hearings (including for recording of
                  evidence) physically, it may hold it at any location agreed to
                  by the parties or where there is no such agreement hold it at
                  a place which the Arbitral Tribunal considers convenient or
                  appropriate, after consulting the parties.
                </p>
              </div>
              <LineBottom />
              <div>
                <b>Explanation:</b> 'Place of Arbitration' shall mean ' Seat of
                Arbitration' and vice versa.
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>29</b>
                </p>
                <p>
                  <b>Applicable law. </b>- (I) The Arbitral Tribunal shall, for
                  deciding the merits of the dispute, apply the law or rules, as
                  the case may be, agreed upon by the parties and in case of
                  failure of suchagreement between the parties, the Arbitral
                  Tribunal shall decide the dispute on merits by applyingthe law
                  or rules which it determines to be appropriate inthe facts and
                  circumstances of the case.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Jn an arbitration other than international commercial_
                  arbitration, the A~bitral _Tribunal shall decide the dispute
                  submitted to arbitration in accordance with the law for the
                  time be1Dg ID force ID India.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The Arbitral Tribunal shall decide as amiable compositeur or
                  ex aequo et bono only if the parties have expressly authorised
                  the Arbitral Tribunal to do so.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  In all cases, the Arbitral Tribunal shall take into account
                  the terms of the contract,_any trade usages applicable to the
                  transaction to the extent that the Arbitral Tribunal considers
                  11 relevant to the arb1tratwn.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>30</b>
                </p>
                <p>
                  <b>Hearings. </b>- (I) Unless the parties have agreed
                  otherwise or where the parties h~ve opted for fast track
                  procedure, the Arbitral Tribunal shall if either party so
                  requests or the Arb1tral Tnbu_nal so dect?es, h?ld a heanng ID
                  a manner as agreed for the presentation of evidence and for
                  oral arguments on 1Dtcnm app!tcallons, 1f any and on the
                  merits of the dispute.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal shall fix the date, time and place of
                  any meeting or hearing and shall give the parties reasonable
                  notice in advance.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  If any party lo the proceedings. wilhoul suflicicnl cause,
                  fails lo appear al a hea ring, the Arbilral Tribunal may
                  proceed with the arbitration and may make the award based on
                  the evidence and submissions before it.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  Unless the parties agree otherwise. all meetings and heari ngs
                  shall be held privately, and any recordings, transcripts,
                  documents or other materials used in the proceedings shall
                  remain confidentia l.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>31</b>
                </p>
                <p>
                  <b>Witnesses- </b> (I) Prior lo heari ng, !he Arbilral
                  Tribunal may require any parly lo give a !isl of witnesses,
                  including expert witnesses, whom it intends to produce, the
                  subject of their testimony and its relevance to the issues.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal shall have !he discrclion lo allow,
                  refuse or limit the number of wilncsscsinlcndcd lo be produced
                  by a party and lo dclcnninc the lime lo be allocated for the
                  oral lcslimony of a witness.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The Arbilral Tribunal shall dclcnninc lhc manner in which
                  witnesses arc lo be examined, andmay direcl Iha! the testimony
                  of any witness be presented in written form.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>32</b>
                </p>
                <p>
                  <b>Appointment of experts by the Arbitral Tribunal. </b>- (I)
                  Unless the parties have agreed otherwise, the Arbi tral
                  Tribunal may-
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  in consultalion with lhc parties, appoint one or more experts
                  to report on specific issues which shall be sci oul by lhc
                  Arbitral Tribunal in writing; and
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>
                  require a party lo give such expert any relevant information,
                  or produce, or provideacccss to any rclcvanl documents, goods
                  or property for inspection.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Any expert so appointed shall submit a report in writing to
                  the Arbitral Tribunal and upon receipt of the said wrincn
                  report, the Arbitral Tribunal shall supply a copy of the
                  report lo the parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  Unless the parties have agreed otherwise, ifa party so
                  requests or if the Arbitral Tribunal considers it necessary,
                  any such expert shall after delivery of his wrincn report,
                  participate in an oral hearing where the parties may
                  crossexamine him.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  Any expert so appointed shall furnish a declaration that he is
                  and shall continue to be impartialand independent.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  The charges and costs relating to the expert shall be borne by
                  the parties, as determined by the Arbitral Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>33</b>
                </p>
                <p>
                  <b>Making of award. </b>- (I) The Arbitral Tribunal, upon
                  being satisfied that !he parties have no further rclcvanl and
                  material evidence to produce or submissions to make, shall
                  declare !hat the proceedings arc closed and the Arbitral
                  Tribunal may, on its own motion or upon application of a party
                  before any award is made, reopen the proceedings.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal shall submit all draft awards to the
                  Registrar within sixty days from the dale on which it declares
                  the proceedings closed unless, in exceptional circumstances
                  and further to an application by the Arbitral Tribunal or on
                  the own motion of the Registrar, the Registrar extends the
                  period for submission of the draft award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The Registrar may suggest changes, within twenty-one days as
                  to the form of the draft award and point out any typographical
                  or clerical errors in the draft award without affecting the
                  decision of the Arbitral Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  The Arbitral Tribunal may make such changes, if any, as it
                  deems fit to the award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  The Arbitral Tribunal may make partial awards on different
                  issues at different times.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  Where the Arbitral Tribunal consists of more than one
                  arbitrator, the Arbitral Tribunal shall decide by a majority.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(7)</p>
                <p>
                  The award shall be made in writing and signed by the Arbitral
                  Tribunal and unless agreed otherwise by the parties in
                  writing, the award shall state the reasons upon which it is
                  based.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(8)</p>
                <p>
                  The award may be executed in any number of counterparts, each
                  of which is an original and all of which together evidence the
                  same award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(9)</p>
                <p>
                  Where there arc three arbitrators and any of them fails lo
                  sign the award, the award shall state the reason for the
                  absence of the signature of such arbitrator.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(10)</p>
                <p>
                  Jf1hc majority arbilralors of the Arbilral Tribunal sign lhc
                  award, it shall be final and binding for1hc purposes of these
                  regulations, provided that all arbilralors were provided with
                  a reasonable opportunity to sign the award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(11)</p>
                <p>
                  The award shall be delivered by the Arbitral Tribunal lo the
                  Registrar, who shall transmit certified copies to the parties
                  upon the full scttlcmcnl of the cosls ofarbi1ra1ion.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(12)</p>
                <p>
                  The Arbilral Tribunal may award interest on any principal sum
                  which is lhc subject of !he arbitration at such rates as the
                  parties may have agreed or, in the absence of such agreement,
                  as the Arbitral Tribunal determines and in respect of any
                  period, as it determines appropriate.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(13)</p>
                <p>
                  In the event of a settlement, if the parties so request, the
                  J\rbitra l Tribu nal may render a consent awa rd recording the
                  settlement, provided always that such award contains an
                  express statement that it is an awa rd made withthe consent of
                  the panics, which need not contain reasons.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(14)</p>
                <p>
                  lfthc parties do not require a consent /\wa rd , the parties
                  shall confirm to the J\rbitra l Tribuna l and the Registrar
                  that a settlement has been reached.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(15)</p>
                <p>
                  The J\rbitral Tribunal shall be discharged and the arbitrati
                  on concluded upon payment o fanyoutstanding costs of the
                  arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(16)</p>
                <p>
                  By agreeing to arbitration under these regulations, the
                  parties agree that an award shall be fin al and binding on the
                  parties from the date it is made.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>34</b>
                </p>
                <p>
                  <b>
                    Correction and interpretation of award, additional award.{" "}
                  </b>
                  - ( I) Within thirty days from the receipt of the arbitral
                  award-
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  a party , with notice to the other party, may request the
                  J\rbitral Tribunal to correct any computati on errors, any
                  clerical or typographical errors or any other errors of a
                  similar nature occurring in the award;
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(b)</p>
                <p>
                  if so agreed by the parties, a party, with notice to the other
                  party, may request the J\rbitral Tribunal to give an
                  interpretation of a specific point or part of the award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  If the Trbitral Tribunal is satisfied with the request made
                  under sub-regulation (1), it shall make the correction or give
                  the interpretation within thirty days from the receipt of the
                  request and the interpretation shall form part of the arbitral
                  award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  The Arbitral Tribunal may correct any error of the type
                  referred to in clause (a) of sub-regulation (I), on its own
                  initiative, within thirty days from the date of the award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  A party with notice to the other party, may request, within
                  thirty days from the receipt of the award, the J\rbitral
                  Tribunal to make an additional award as to claims presented in
                  the arbitral proceedings but omitted from the award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  If the Arbitral Tribunal is satisfied with the request made
                  under sub-regulation (4), it shall make the additional award
                  within sixty days from the receipt of such request.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  The Arbitral Tribunal may extend, if necessary, the period
                  within which it shall make a correction, give an
                  interpretation or make an additional award under
                  sub-regulation (2) or sub-regulation (5).
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(7)</p>
                <p>
                  The regulation 33 shall apply to a correction or
                  interpretation of the award or to an additional award.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>35</b>
                </p>
                <p>
                  <b>Fees and expenses of arbitration. </b>- (I) The fees of the
                  arbitrator and the administration fee shall be fixed by the
                  Registrar in accordance with the fee specified in the
                  Schedule.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal's reasonable out-of-pocket expenses
                  necessarily incurred and other allowances shall be reimbursed
                  in accordance with the applicable practice directions.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>36</b>
                </p>
                <p>
                  <b>Deposits for costs of arbitration. </b>- (I) The Registrar
                  shall fix the amount of deposits towards the costs of
                  arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Unless the Registrar directs otherwise, fifty per cent. of
                  such deposits shall be payable by the claimant and the
                  remaining fifty per cent. of such deposits shall be payable by
                  the respondent and the Registrar may fix separate advances on
                  costs for claims and counter-claims, respectively.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  Where the amount of the claim or the counter-claim is not
                  quantifiable at the time payment i_sdue, a provisional
                  estimate of the costs of the arbitration shall be made by the
                  Registrar, based on the nature of the dispute, the contract
                  amount and the circumstances of the case, which shall be
                  adjusted subsequently.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(4)</p>
                <p>
                  The Registrar may from time to time direct the parties to make
                  further payments towards costs of the arbitration incurred or
                  to be incurred on behalf of, or for the benefit of, the
                  parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(5)</p>
                <p>
                  If a party fails to make the deposits as directed, the
                  Registrar ~ay'. after consuUing the Arbitral:ribunal and after
                  informing the parties, direct the Arbitral Tribunal 1_0
                  suspend the arb1tratton proceed1Dgs and set a _renod, not
                  c~cccding sixty days, on the expiry of which the relevant
                  claims or_countcr-cla1ms sh~II stand term_lDatcd without
                  p_reJud1cc to the parties reintroducing the same claims or
                  countcr-clanns ID another procccd1Dg de novo, ID accordance
                  wnh law.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(6)</p>
                <p>
                  Parties arc jointly and severa lly liable for the deposits for
                  costs of the arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(7)</p>
                <p>
                  J\ny party is free to pay the whole of the deposits towards
                  costs of the arbitration in respect of the claim or the
                  countercl aim, in case the oth er party fails to pay its
                  share.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(8)</p>
                <p>
                  If the arbitration is settled or disposed of without a hea
                  ring, the cos ls of arb itra tion sh'.1 11 be fin aUy
                  delcrrni_ned_ by the Registrar having regard to the
                  circumstances of the case, includtng the stage of procecdmgs
                  al wl11 ch the arb1trat1on is settled or disposed.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(9)</p>
                <p>
                  In the event that the costs of arbitration determined a_re
                  less than the deposits made, there sha ll be a rcfond made to
                  the parties by the Centre in such proporti ons as the deposits
                  were made un lcssolh crw1sc agreed by th e parti es.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(10)</p>
                <p>
                  All deposits shall be made to and held by the Centre and any
                  interest which may accrue on such deposits sha ll be retained
                  by it.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(11)</p>
                <p>
                  The Centre shall have a lien on the arbitral award for any
                  unpaid costs of the arbi tration and thc award shall not be
                  notified to the parties unless all such costs have been foll y
                  paid lo the Centreby the pa rti es or by any one of them.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>37</b>
                </p>
                <p>
                  <b>Costs of arbitration. </b>- ( 1) The Arbitral Tribunal
                  shall specify in the award, the total amo un t of the costs of
                  the arbitration, and unless the parties have agreed otherwise,
                  the Arbitra l Tribun al shall determine in the award, the
                  apportionment of the costs of the arbitration among the
                  parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p>(a) The term "costs of the arbitration" includes -</p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p>
                  (b) the administration fees of the Centre and its expenses;
                  and
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p>
                  (c) the costs of expert advice and of other assistance
                  reasonably required by the A rbitra lTribunal
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>38</b>
                </p>
                <p>
                  <b>Legal and other costs. </b>- (I) The Arbitral Tribunal
                  shall have the authority to order in its award that all or a
                  part of the legal or other costs ofa party be paid by another
                  party.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Arbitral Tribunal shall take into account in making its
                  decision as to costs, circumstances it considers relevant,
                  including the extent to which each party has conducted the
                  arbitration in an expeditious manner.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>39</b>
                </p>
                <p>
                  <b> Appointment fees. </b>- (I) The appointment fees, as
                  specified in the Schedule shall be payable when a request for
                  appointment of arbitrator has been made to the Centre in an ad
                  hoc arbitration and where parties have agreed for the Centre
                  to make such appointment either before or after the dispute
                  has arisen and such appointment fee shall be payable by the
                  party making the request for appointment.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  Where a court refers a matter lo the Centre for appointment of
                  arbitrator on an application made by a party for such
                  appointment, then the appointment fee shall be payable by the
                  party who has made an application to the court forthc
                  appointment of arbitrator.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  Where a court on its own motion refers a matter to the Centre
                  for appointment of arbitrator, the appointment fee shall be
                  payable by both the parties in equal share or either party may
                  pay the entire amount of appointment fee.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>40</b>
                </p>
                <p>
                  <b>Exclusion of liability. </b>- (I) The Centre, the
                  Chairperson, Members, Chief Executive Officer, Registrar,
                  Member of the advisory panel or Chamber of Arbitration of the
                  Centre, other officers, employees, any arbitrator or emergency
                  arbitrator, shall not be liable to any person for any
                  negligence, act or omission in good faith, in connection with
                  any arbitration conducted under these regulations.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Centre, the Chairperson, Members, Chief Executive Officer,
                  Registrar, Member of the advisory panel or Chamber of
                  Arbitration of the Centre, other officers, employees, any
                  arbitrator or emergency arbitrator, shall not be under any
                  obligation to make any statement in connection with any
                  arbitration conducted under these regulations and no party
                  shall seek to make the Chairperson, any Members, officers,
                  employees, any arbitrator or emergency arbitrator actas a
                  witness in any legal proceedings in connection with any
                  arbitration conducted under these regulations.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>41</b>
                </p>
                <p>
                  <b>Waiver of right to object. </b>- A party who knows or ought
                  to know of a failure to comply with any of the provisions, or
                  requirement under these regulations, and which arc applicable
                  to the proceedings, any direction given by the Arbitral
                  Tribunal, or any requirement under the arbitration agreement
                  relating to the constitution of the Arbitral Tri_bunal orthe
                  conduct of the prnceedings _and_ yet p~oc_ceds with _thc
                  arbitration without promptly stating its objection, or ,fa
                  period 1s provided for statmg that obJectton, w1thm that
                  period, shall be deemed to have waived its right to obj ect.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>42</b>
                </p>
                <p>
                  <b>Confidentiality. </b>- (I) The parties, the Arbitral
                  Tribunal, an emergency arbitrator, Members of the Centre and
                  the Centre shall at all times treat all matters relating to
                  the arbitration and the award including the proceedings as
                  confidenllal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  A party or any arbitrator shall not, without the prior written
                  consent of all the parties, discloscto a third party an such
                  matter, except-
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(a)</p>
                <p>
                  for the purpose of making an applica tion to a co urt of
                  competent jurisdiction, toenfo rce or challenge the award; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p>
                  (b) pursuant to the order made by a co urt of competent
                  jurisdi cti on; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p>
                  (c) for the purpose of pu rsuing or enforci ng a legal right
                  or claim; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(d)</p>
                <p>
                  in compliance wi th the provisions of any laws which arc
                  binding on th e party making the disc losure; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(e)</p>
                <p>
                  in compli ance with the request or requirement of any regul
                  atory body or othcrauthority under any law; or
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex", paddingLeft: "45px" }}>
                <p style={{ marginRight: "10px" }}>(f)</p>
                <p>
                  pursuant to an order by the Arbitral Tribun al on application
                  by a party wi th propernoticc to the other parties.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(3)</p>
                <p>
                  In this rcgulation1 "mailers relating to the proceedings"
                  means the ex istence of the proceedings and the pleadings,
                  evidence and other materials in the arbitration proceedings
                  and allot her doc uments produced by another party in the
                  proceedi ngs or the award arising from the proceedi ngs, but
                  excludes any matter that is otherwise in the publi c domain .
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <b>
                  Decisions of Chairperson, Members, Chief Executive Officer and
                  Registrar.-
                </b>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(1)</p>
                <p>
                  Subject to the provisions o f these regulations, the decisions
                  of the Chairperson, Members, Chief Executive Officer and
                  Registrar with respect to all matters relating to an
                  arbitration conducted under these regu lations shall be
                  conclusive and binding upon the parties and the Arbitral
                  Tribunal.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(f)</p>
                <p>
                  The Chairperson, Members, Chief Executive Officer and the
                  Registrar shall not be required to provide reasons for such
                  decisions, unless expressly provided for in these regulations.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>
                  <b>44</b>
                </p>
                <p>
                  Miscellaneous.- (I) In all matters not expressly provided for
                  in these regulations, the Chairperson, Members, Chief
                  Executive Officer, Registrar and the Arbitral Tribunal shall
                  act in the spirit of these regulations and shall make every
                  reasonable effort to ensure the fair, expeditious and
                  economical conclusion of the arbitration.
                </p>
              </div>
              <LineBottom />
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>(2)</p>
                <p>
                  The Centre may from time to time issue practice directions for
                  implementation of these regulations, for the purpose of
                  facilitating the administration of arbitration.
                </p>
              </div>
              <LineBottom />
            </CustomList>
          </Content>
        </RuleContainer>
        <Footer />
      </div>
    </>
  );
}

export default Regulations;

const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;

const HeadingOne = styled.div`
  font-size: 20px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.BTN_GREEN};
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 14px;
    text-align: center;
  }
`;

const Padding = styled.li`
  margin: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  &.session-container {
    display: flex;
    margin-bottom: 20px;
  }
  &.session-no {
    font-weight: bold;
    text-align: left;
  }
  &.session-text {
    text-align: left;
    text-indent: 20px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 40px;
  }
`;

export const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin: 40px 30px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    margin: 40px auto;
  }
`;

const LineBottom = styled.div`
  margin-bottom: 10px;
`;

const CustomList = styled.ol`
  &.custom-list {
    list-style-type: none;
    counter-reset: list-counter;
  }

  &.custom-list li {
    counter-increment: list-counter;
    text-indent: -40px;
    padding-left: 25px;
  }

  &.custom-list li::before {
    content: "(" counter(list-counter, lower-alpha) ") ";
    margin-right: 10px;
  }
`;
