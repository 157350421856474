import React from "react";
import { CustomRadioGroup } from "../../../common/FormInputs";
import {
  StyledFormElementsWrapper,
  StyledFormElementsWrapper1,
  StyledLabel,
} from "../Styles";

const CaseTypeRadioButton = ({ values, handleChange, resolutionKind }) => {
  return (
    <>
      <StyledFormElementsWrapper1 style={{ marginBottom: 15, marginTop: 15 }}>
        <StyledLabel>Select a Case Type</StyledLabel>
        <CustomRadioGroup
          radioInputs={[{ label: "Arbitration", value: "arbitration" }]}
          name={"resolutionKind"}
          handleChange={(e) => handleChange(e, "resolutionKind")}
          {...{ values }}
        />
      </StyledFormElementsWrapper1>
      {(resolutionKind === "mediation" || resolutionKind === "arbitration") && (
        <StyledFormElementsWrapper style={{ marginBottom: 15, marginTop: 15 }}>
          <StyledLabel>Select Arbitrator Type</StyledLabel>
          <div className="mediator-type">
            <CustomRadioGroup
              radioInputs={[
                {
                  label: `${"IIAC Institutional Arbitration – with IIAC Arbitral Panel & Rules"}`,
                  value: "normal",
                },
                {
                  label: `${"Adhoc Arbitration – with parties’ agreed upon Arbitral Panel & Rules"}`,
                  value: "adhoc",
                },
              ]}
              name={"subscriptionKind"}
              handleChange={(e) => handleChange(e, "subscriptionKind")}
              {...{ values }}
            />
          </div>
        </StyledFormElementsWrapper>
      )}
    </>
  );
};

export default CaseTypeRadioButton;
