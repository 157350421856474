import React, { useEffect } from "react";
import { useParams } from "@reach/router";
import useNotification from "../../hooks/useNotification";

const BuildDeskCallback = () => {
  const params = useParams();
  const { setTransaction } = useNotification();

  useEffect(() => {
    const handlePaymentCallback = async () => {
      try {
        let frontendUrl = params.frontendUrl;
        const response = await fetch(`${frontendUrl}/billDeskCallBack`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        setTransaction(result);
        if (result.success) {
          console.log("Payment successful:", result.transactionId);
          // Update UI to show success
        } else {
          console.error("Payment failed:", result.transactionId);
          // Update UI to show failure
        }
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };
    handlePaymentCallback(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default BuildDeskCallback;
