import { navigate, useLocation } from "@reach/router";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import Labels from "../../../helpers/labels.json";
import useCartCount from "../../../hooks/useCartCount";
import ActionBar from "../../common/ActionBar";
import CasesTable from "../../common/CasesTable/index";

const Index = () => {
  const location = useLocation();
  const { stats } = useCartCount();
  const [caseType, setCaseType] = useState("");
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!_.isEmpty(stats)) {
      if (!!searchParams.get("caseType")) {
        setCaseType(searchParams.get("caseType"));
      } else {
        setCaseType("arbitration");
      }
    }
  }, [searchParams, stats]);

  const actions = [
    {
      text: "Create Bulk Case",
      props: {
        onClick: () =>
          navigate(`/dashboard/cases/bulk-create?caseType=${caseType}`),
      },
    },
    {
      text: "Create Case",
      props: {
        onClick: () => navigate(`/dashboard/cases/create?caseType=${caseType}`),
      },
    },
  ];
  return (
    <div>
      <ActionBar
        actions={actions}
        breadcrumbs={[_.startCase(Labels.my_cases), _.startCase(caseType)]}
      />
      <Padding>
        {caseType && (
          <CasesTable
            caseType={caseType}
            status={searchParams.get("status")}
            currentCaseType={caseType}
            draftSelection
          />
        )}
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
