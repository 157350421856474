import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";

function PrivacyPolicy(props) {
  return (
    <>
      <div>
        <Header />
        <H2 style={{ marginTop: "20px" }}>Privacy Policy</H2>
        <RuleContainer></RuleContainer>
        {/* {settingsLoading ? (
          <Loader>
            <CircularProgress size={60} thickness={3} />
          </Loader>
        ) : (
          <Container>
            {setting?.tnc?.map((terms, index) => (
              <div className="ql-editor" key={index}>
                <Padding>
                  <SubHeading>{terms?.question}</SubHeading>
                  <div
                    dangerouslySetInnerHTML={{ __html: terms?.answer }}
                  ></div>
                </Padding>
              </div>
            ))}
          </Container>
        )} */}
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;

const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 40px;
  }
`;

export const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin: 40px 30px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    margin: 40px auto;
  }
`;
