import React, { useState, useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import theme from "../../../assets/theme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircularProgress } from "@material-ui/core";
import { HyperLink } from "../styled-components";

const useStyles = makeStyles((themes) => ({
  root: {
    fontFamily: `${theme.fonts.primaryFontRegular}`,
    background: `linear-gradient(to right, ${COLORS.COLOR_DARK} 0%, ${COLORS.COLOR_DARK} 50%, ${COLORS.TABLE_GREY_CELL} 50%)`,
    fontWeight: "bold",
    "& .MuiSvgIcon-root": {
      position: "relative",
      right: "0",
      top: "0",
    },
  },
  root1: {
    marginRight: "10px",
  },
  expanded: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  expandIcon: {
    borderRadius: "50%",
    backgroundColor: "white",
    color: `${COLORS.BTN_GREEN}`,
  },
}));

export default function SmallTable({
  state,
  column,
  selection,
  onSelectionChange = () => {},
  detailPanel,
  top,
  left,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailPanelText, setDetailPanelText] = useState("Details Split Up");
  const [detailPanelDiv, setDetailPanelDiv] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const setExpandedPanel = async () => {
      await setExpanded(state.data !== undefined ? state.data[0]?.id : false);
    };
    setExpandedPanel();
    const setLoader = () => {
      setLoading(_.isEmpty(state) && _.isEmpty(column.data) ? true : false);
    };
    setLoader();
    if (state?.data?.length) {
      setData(state.data);
    } else {
      setData([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  useEffect(() => {
    if (!_.isEmpty(data) && data?.length) {
      let isSelectedAll = data?.every((el) => el?.tableData?.checked);
      setSelectAll(isSelectedAll);
    } else {
      setSelectAll(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePanelClick = () => {
    setDetailPanelDiv(!detailPanelDiv);
    detailPanelDiv
      ? setDetailPanelText("Detail Split Up")
      : setDetailPanelText("Hide Split Up");
  };

  /**
   * @description Function execute when handle selection
   * @param {*} dataClicked
   * @param {*} idx
   */
  const onHandleSelection = (event, dataClicked, idx) => {
    event.stopPropagation();
    const selectedRows = [];
    dataClicked = {
      ...dataClicked,
      tableData: {
        ...dataClicked?.tableData,
        checked: !dataClicked?.tableData?.checked,
      },
    };
    const originalData = data?.map((el, index) => {
      if (index === idx) {
        return {
          ...el,
          tableData: {
            ...el?.tableData,
            checked: !el?.tableData?.checked,
          },
        };
      } else {
        return {
          ...el,
        };
      }
    });
    setData(originalData);
    const findSelecteds = (list) => {
      list.forEach((row) => {
        if (row.tableData.checked) {
          selectedRows.push(row);
        }
      });
    };
    findSelecteds(originalData);
    onSelectionChange(selectedRows, dataClicked);
  };

  /**
   * @description Function is use to handle the select all
   * @param {*} flag
   */
  const handleSelectAll = (flag) => {
    onSelectionChange(flag ? data : []);
    setSelectAll(flag);
  };

  return (
    <>
      {!loading ? (
        <>
          {selection ? (
            <Select
              top={top}
              left={left}
              onClick={() => handleSelectAll(!selectAll)}
            >
              <Icon
                src={
                  selectAll
                    ? require("../../../assets/images/checkBoxSelected.svg")
                    : require("../../../assets/images/checkbox.svg")
                }
              />
              <p>Select All</p>
            </Select>
          ) : null}
          <div className="custom-table">
            {!_.isEmpty(data) &&
              data?.map((item, idx) => {
                return (
                  <ExpansionPanel
                    key={idx}
                    className={classes.root}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                  >
                    <ExpansionPanelSummary
                      className={classes.root1}
                      expandIcon={
                        <ExpandMoreIcon className={classes.expandIcon} />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <PanelSummary>
                        <PanelLeftTop>
                          {selection ? (
                            <Icon
                              onClick={(event) => {
                                onHandleSelection(event, item, idx);
                              }}
                              src={
                                item?.tableData?.checked
                                  ? require("../../../assets/images/checkBoxSelectedWhite.svg")
                                  : require("../../../assets/images/checkboxWhite.svg")
                              }
                            />
                          ) : null}
                          {column.columns[0].title}
                        </PanelLeftTop>
                        <PanelRightTop
                          style={{
                            color: `${COLORS.BTN_GREEN}`,
                          }}
                        >
                          {column.columns[0].render(item)}
                        </PanelRightTop>
                      </PanelSummary>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.expanded}>
                      <PanelDetail>
                        {column.columns
                          .filter((_, index) => {
                            return index !== 0;
                          })
                          .map((i) => {
                            return (
                              <>
                                <Panel>
                                  <PanelLeft selection={selection}>
                                    {i.title}
                                  </PanelLeft>
                                  <PanelRight>
                                    {i.render(item)}
                                    {detailPanel !== undefined &&
                                    i.title === "Description" ? (
                                      <HyperLink
                                        onClick={() => {
                                          handlePanelClick();
                                        }}
                                        style={{ marginTop: 10 }}
                                      >
                                        {detailPanelText}
                                      </HyperLink>
                                    ) : null}
                                  </PanelRight>
                                </Panel>
                                {detailPanel !== undefined &&
                                i.title === "Description" &&
                                detailPanelDiv ? (
                                  <DetailPanel>
                                    {detailPanel[0].render(item)}
                                  </DetailPanel>
                                ) : null}
                              </>
                            );
                          })}
                      </PanelDetail>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
          </div>
        </>
      ) : (
        <div className="loader" style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export const Select = styled.div`
  position: absolute;
  top: ${({ top }) => (top ? top : "0px")};
  right: ${({ left }) => (!left ? 0 : "")};
  // top: 135px;
  left: ${({ left }) => (left ? 0 : "")};
  font-size: 16px;
  width: 110px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primaryFontSemiBold};

  @media (min-width: 780px) {
    display: none;
  }
`;

const PanelSummary = styled.div`
  width: 100%;
  display: flex;
  padding-left: 18px;
`;

const PanelDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 44px;
  padding-bottom: 20px;
  word-break: break-word;
`;

const PanelLeftTop = styled.div`
  width: 50%;
  color: white;
  display: flex;
  align-items: center;
`;
const PanelLeft = styled.div`
  width: 50%;
  color: white;
  align-items: center;
  padding-left: 3px;
  margin-left: ${({ selection }) => (selection ? "28px" : "0px")};
`;

const PanelRightTop = styled.div`
  width: 50%;
  text-align: center;
  margin-left: 20px;
`;
const PanelRight = styled.div`
  width: 50%;
  margin-left: 18px;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
`;

const DetailPanel = styled.div`
  margin: 0 -16px 0 -17px;
  padding: 15px 18px 20px 18px;
  background-color: ${COLORS.TABLE_GREY_CELL};
`;

export const Icon = styled.img`
  margin-right: 10px;
  width: 18px;
  height: 18px;
`;
