import React, { useState, useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { navigate } from "@reach/router";
import CustomTable from "../CustomTable/CustomTable";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import {
  getErrorMessage,
  getPartyLabelByStatus,
  parseTimeStamp,
} from "../../../helpers/functions";
import PartyService from "../../../services/PartyService";
import labels from "../../../helpers/labels.json";

function handleNavigateToParty(type, id) {
  switch (type) {
    case "active":
      navigate(`/dashboard/parties/${id}`);
      break;
    default:
      navigate(`/dashboard/parties/draft/${id}`);
      break;
  }
}

async function getParties(query = "") {
  try {
    const response = await PartyService.getParties(query);
    const stats = await PartyService.partyStats();
    return { ...response, stats };
  } catch (error) {
    throw error;
  }
}

const filterByStatus = (selectedFilter) => {
  let key = "";
  switch (selectedFilter) {
    case labels["filters.draft"]:
      key = "draft";
      break;
    case labels["filters.under_verify"]:
      key = "underReview";
      break;
    case labels["filters.active"]:
      key = "active";
      break;
    default:
      break;
  }
  return key;
};

const initState = [
  { label: labels["filters.all"], key: "allCount" },
  { label: labels["filters.active"], key: "activeCount" },
  { label: labels["filters.draft"], key: "draftCount" },
  { label: labels["filters.under_verify"], key: "underReviewCount" },
];

export default function PartyTable() {
  const [state, setState] = useState();
  const MTRef = useRef();
  const [filters, setFilters] = useState(initState);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState();

  useEffect(() => {
    if (selectedFilter && MTRef?.current?.onQueryChange) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (!_.isEmpty(state)) {
      setFilters([
        { label: labels["filters.all"], value: state.total },
        { label: labels["filters.draft"] },
        { label: labels["filters.under_verify"] },
      ]);
    }
  }, [state]);

  function setStats(stats) {
    const mapped = initState.map((filter) => {
      let returnData = {
        label: filter.label,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  const getPartyAgent = (agentList) => {
    if (agentList[0]?.role === "primary") {
      return "Primary Agent";
    } else {
      return "Agent";
    }
  };

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        status: filterByStatus(selectedFilter),
        perPage: 10,
        search: query.search,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getParties(stringParams)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  const columns = [
    {
      field: "id",
      title: labels["table.party_id"],
      tooltip: "Unique Identifier for the Party across IIAC platform",
      render: (rowData) => (
        <HyperLink
          onClick={() => handleNavigateToParty(rowData?.status, rowData?.id)}
        >
          {rowData.id}
        </HyperLink>
      ),
      headerStyle: {
        paddingLeft: 35,
      },
    },
    {
      field: "name",
      title: labels["table.party_name"],
      render: (rowData) => (
        <Bold
          onClick={() => handleNavigateToParty(rowData?.status, rowData?.id)}
        >
          {rowData.name}
        </Bold>
      ),
    },
    {
      field: "kind",
      title: labels["table.party_type"],
      tooltip: "Claimant or Respondent",
      render: (rowData) => <>{_.capitalize(rowData.kind)}</>,
    },
    {
      field: "role",
      title: labels["table.party_role"],
      tooltip:
        "Your role is either Claimant or Respondent with respect to a case",
      sorting: false,
      render: (rowData) => (
        <>
          {rowData?.agents?.length > 0 ? (
            getPartyAgent(rowData?.agents)
          ) : (
            <Center>-</Center>
          )}
        </>
      ),
    },
    {
      field: "documentKind",
      tooltip: "Identifier",
      title: labels["table.party_idKind"],
      render: (rowData) => (
        <>
          {rowData.documentKind === "voter"
            ? "VOTER ID"
            : rowData.documentKind === "aadhar"
            ? "AADHAAR"
            : _.upperCase(rowData.documentKind)}
        </>
      ),
    },
    {
      field: "status",
      title: labels["table.status"],
      tooltip: "Status of the Party",
      sorting: false,
      render: (rowData) => getPartyLabelByStatus(rowData.status),
    },
    {
      field: "created_at",
      tooltip: "Party Created Date",
      title: labels["table.created"],
      render: (rowData) => (
        <>
          {rowData?.submittedOn
            ? moment(rowData.submittedOn).format("DD/MM/YYYY")
            : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
        </>
      ),
    },
  ];

  return (
    <CustomTable
      hidePagination={state?.lastPage === 1}
      pageSize={state?.data?.length ? state?.data?.length : 10}
      pluralTitle={labels["parties"]}
      singularTitle={labels["party"].toLowerCase()}
      placeholderText={labels["table.partySearch"]}
      {...{
        columns,
        data,
        filters,
        selectedFilter,
        setSelectedFilter,
        MTRef,
      }}
      state={state}
    />
  );
}

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;
const Bold = styled.span`
  cursor: pointer;
  @media ${theme?.breakpoints?.sm_up} {
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

const Center = styled.div`
  margin-left: 0;
  @media ${theme?.breakpoints?.lg_up} {
    margin-left: 35px;
  }
`;
