import { useMediaQuery, useTheme } from "@material-ui/core";
import { navigate, useLocation } from "@reach/router";
import { Formik, yupToFormErrors } from "formik";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef, createRef } from "react";
import theme from "../../../assets/theme";
import { caseDocumentType, currencyType } from "../../../helpers/constants";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import { CreateCaseStep1Schema } from "../../../helpers/validationSchema";
import useCartCount from "../../../hooks/useCartCount";
import useLoader from "../../../hooks/useLoader";
import AWSService from "../../../services/AWSService";
import CaseService from "../../../services/CaseService";
import PartyService from "../../../services/PartyService";
import ActionBar from "../../common/ActionBar";
import AlertDialog from "../../common/Alert";
import FormField from "../../common/FormField/FormField";
import { CustomFileUpload } from "../../common/FormInputs";
import { HyperLink } from "../../common/styled-components";
import AdditionalMediator from "./components/AdditionalMediator";
import CaseActionCTA from "./components/CaseActionCTA";
import CaseHeader from "./components/CaseHeader";
import CaseTypeRadioButton from "./components/CaseTypeRadioButton";
import MultipleUpload from "./components/MultipleUpload";
import PartySelect from "./components/PartySelect";
import TotalClaimValue from "./components/TotalClaimValue";
import { setInitalFormValue, setPostPayload } from "./function";
import {
  Container,
  CreateCaseContainer,
  H2,
  StyledForm,
  StyledFormElementsWrapper,
  StyledLabel,
} from "./Styles";

const inputs = [
  {
    type: "textarea",
    name: "description",
    label: "Case Description",
    required: true,
  },
];

const Index = ({ caseId }) => {
  const [menuItems, setMenuItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { setTriggerCount } = useCartCount();
  const location = useLocation();
  const [dialogData, setDialogData] = useState(false);
  const [open, setOpen] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));
  const breadcrumbs = ["My Cases", "Create New Case"];
  const [length, setLength] = useState(1);
  const fileRef = useRef([]);

  const [caseObj, setCaseObj] = useState({});
  useEffect(() => {
    async function getCase(caseId) {
      try {
        setLoader({ state: true });
        const response = await CaseService.getCase(caseId);
        if (response) {
          setCaseObj(response);
          if (response.caseItems.length > 0) {
            setLength(response.caseItems.length);
          }
        }
      } catch (error) {
      } finally {
        setLoader({ state: false });
      }
    }
    if (caseId) {
      getCase(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  useEffect(() => {
    async function getParties(params) {
      try {
        setLoader({ state: true });
        const response = await PartyService.getParties(
          "?perPage=50&status=active",
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id }) => ({ label: name, value: id })),
          );
        } else {
          enqueueSnackbar(
            "You need to create a Party inorder to create a case",
            {
              variant: "error",
              autoHideDuration: 3000,
            },
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "my_cases":
        navigate("/dashboard/cases");
        break;
      default:
        break;
    }
  };

  const handleSuccess = (response, draft) => {
    if (response?.case?.id) {
      setTriggerCount(true);
      if (draft) {
        enqueueSnackbar("Case added to draft successfully", {
          variant: "success",
        });
        navigate(`/dashboard/cases?caseType=${response?.case?.resolutionKind}`);
      } else {
        navigate(
          `/dashboard/cases/create/${response.case.id}/step-2?caseType=${response?.case?.resolutionKind}`,
        );
      }
    }
  };

  const handleDeleteAgreement = (index, agreement_urls, setFieldValue) => {
    if (agreement_urls?.length) {
      const filtered = agreement_urls.filter((v, idx) => idx !== index);
      setFieldValue("agreement_urls", filtered);
    }
  };

  useEffect(() => {
    if (fileRef.current.length !== length) {
      // add or remove refs
      fileRef.current = Array(length)
        .fill()
        .map((_, i) => fileRef.current[i] || createRef());
    }
  }, [fileRef.current.length, length]);

  async function deleteClaimItem(id, claimantPartyId, caseId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await CaseService.deleteCaseItem(
          caseId,
          id,
          claimantPartyId,
        );
        if (response) {
          resolve(response);
        }
        reject("Error");
      } catch (error) {
        reject(error);
      }
    });
  }

  async function addClaimItem(claim_item, claimantPartyId, caseId) {
    return new Promise(async (resolve, reject) => {
      try {
        claim_item.claimValue = Number(claim_item.claimValue);
        const response = await CaseService.createCaseItem(
          claim_item,
          caseId,
          claimantPartyId,
        );
        if (response) {
          resolve({ ...response.caseItem, index: claim_item?.index || 0 });
        }
        reject("Error");
      } catch (error) {
        reject(error);
      }
    });
  }

  async function updateClaimItem(
    claim_id,
    claim_item,
    claimantPartyId,
    caseId,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        claim_item.claimValue = Number(claim_item.claimValue);
        const response = await CaseService.updateCaseItem(
          caseId,
          claim_id,
          claim_item,
          claimantPartyId,
        );
        if (response) {
          resolve(response.caseItem);
        }
        reject("Error");
      } catch (error) {
        reject(error);
      }
    });
  }

  async function getDeletedItems(orginalData, currentData) {
    const originalIDs = await _.map(orginalData, "id");
    const currentIDs = await _.map(currentData, "id");
    const deletedIDs = await _.difference(originalIDs, currentIDs);
    return deletedIDs;
  }

  async function onFormSubmit(params, props, isDraft = false) {
    let values = JSON?.parse(JSON.stringify(params));
    values.claim_items_data = params?.claim_items_data?.map((ci) => ({
      ...ci,
      claimValue: ci?.claimValue?.toString()?.split(",")?.join(""),
    }));
    if (caseObj?.editFlag) {
      navigate(
        `/dashboard/cases/create/${caseId}/step-2?caseType=arbitration`,
        {
          state: {
            values: values,
            editFlag: caseObj?.editFlag,
          },
        },
      );
    } else {
      try {
        setLoader({ state: true, message: "submitting form..." });
        let payload = { ...params };
        payload = setPostPayload(payload);
        const new_claim_items = values.claim_items_data.filter((ci) => !ci.id);
        const claimantPartyId = values?.claimantPartyId;
        const old_claim_items = values.claim_items_data.filter((ci) => ci.id);
        const deleted_claim_items = await getDeletedItems(
          caseObj.caseItems,
          old_claim_items,
        );
        let count = 0;
        console.log(count);
        let finalClaimItems = values.claim_items_data.map((el, index) => {
          return { ...el, index };
        });
        const hasDuplication =
          payload?.requestedMediators?.length &&
          payload?.requestedMediators?.filter(
            (mediator, index) =>
              payload?.requestedMediators?.findIndex(
                (el) => el?.email === mediator?.email,
              ) !== index,
          )?.length;
        if (hasDuplication) {
          // eslint-disable-next-line no-throw-literal
          throw "Duplicate email IDs found in Arbitrators";
        }
        if (caseId) {
          const response = await CaseService.updateDraft(
            payload,
            caseId,
            params.claimantPartyId,
          );
          handleSuccess(response, isDraft);
          if (response) {
            if (deleted_claim_items) {
              count = -deleted_claim_items.length;
              for (const claim_item_id of deleted_claim_items) {
                const deleted_item = await deleteClaimItem(
                  claim_item_id,
                  claimantPartyId,
                  caseId,
                );
                if (deleted_item) {
                  count += 1;
                }
              }
            }

            if (old_claim_items) {
              for (const claim_item of old_claim_items) {
                const addedItem = await updateClaimItem(
                  claim_item.id,
                  claim_item,
                  claimantPartyId,
                  caseId,
                );
                if (addedItem.id) {
                  const index = _.findIndex(
                    finalClaimItems,
                    (ci) => ci?.id === addedItem?.id,
                  );
                  finalClaimItems[index] = addedItem;
                  count += 1;
                }
              }
            }
            if (new_claim_items) {
              for (const claim_item of new_claim_items) {
                if (claim_item.title && claim_item.claimValue) {
                  // const caseId = response?.case?.id;
                  const addedItem = await addClaimItem(
                    claim_item,
                    claimantPartyId,
                    caseId,
                  );
                  if (addedItem.id) {
                    const index = _.findIndex(
                      finalClaimItems,
                      (ci) => ci?.index === addedItem?.index,
                    );
                    finalClaimItems[index] = addedItem;
                    setCaseObj({ ...caseObj, caseItems: finalClaimItems });
                    count += 1;
                  }
                }
              }
            }
          }
        } else {
          const response = await CaseService.createCase(
            payload,
            params.claimantPartyId,
          );
          handleSuccess(response, isDraft);
          if (response) {
            if (new_claim_items) {
              for (const claim_item of new_claim_items) {
                if (claim_item.title && claim_item.claimValue) {
                  const caseId = response?.case?.id;
                  const addedItem = await addClaimItem(
                    claim_item,
                    claimantPartyId,
                    caseId,
                  );
                  if (addedItem.id) {
                    const index = _.findIndex(
                      finalClaimItems,
                      (ci) => ci?.index === addedItem?.index,
                    );
                    finalClaimItems[index] = addedItem;
                    setCaseObj({ ...caseObj, caseItems: finalClaimItems });
                    count += 1;
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        if (caseObj?.resolutionKind) {
          navigate("/dashboard/cases?caseType=arbitration");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  };

  /**
   * @description Function to show the delete warning
   * @param {*} id
   */
  const deleteWaring = (id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () => deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  /**
   * @description Function to reset the values
   * @param {*} setFieldValue
   */
  const resetValuewhenCaseTypeChanges = (setFieldValue, type, values) => {
    if (values?.requestedMediators?.length) {
      if (type === "mediation") {
        setFieldValue("requestedMediators", [values?.requestedMediators[0]]);
      } else if (type === "negotiation") {
        setFieldValue("requestedMediators", []);
      }
    }
    if (type === "negotiation") {
      setFieldValue("subscriptionKind", "normal");
    }
  };

  /**
   * @description Function to open the confirmation popup when switch case radio buttons
   * @param {*} value
   * @param {*} setFieldValue
   * @param {*} name
   */
  const openConfirmationPopUp = (value, setFieldValue, name, values) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Yes",
      secondaryBtnText: "No",
      clickPrimaryBtn: () => {
        setFieldValue(name, value);
        resetValuewhenCaseTypeChanges(setFieldValue, value, values);
        setOpen(false);
      },
      clickSecondarybtn: () => setOpen(false),
      desc: `Are you sure you want to switch to ${_.capitalize(value)}?`,
      heading: "Confirmation",
      descriptionTextStyle: {
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Container>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <CreateCaseContainer className="CreateCase">
        <Formik
          initialValues={setInitalFormValue(caseObj, menuItems, searchParams)}
          validate={(values) => {
            try {
              CreateCaseStep1Schema.validateSync(values, {
                abortEarly: false,
                values,
              });
            } catch (e) {
              return yupToFormErrors(e);
            }
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => (
            <StyledForm>
              {/* header part */}
              <CaseHeader
                resolutionKind={values?.resolutionKind}
                onClick={() => deleteWaring(caseId)}
                showDelete={!!caseId}
              />
              <H2>Enter Case Details</H2>
              {/* radio button */}
              <CaseTypeRadioButton
                values={values}
                handleChange={(e, type) => {
                  if (caseId) {
                    openConfirmationPopUp(
                      e?.target?.value,
                      setFieldValue,
                      type,
                      values,
                    );
                  } else {
                    handleChange(e);
                  }
                }}
                resolutionKind={values.resolutionKind}
              />

              {values?.resolutionKind === "arbitration" && (
                <StyledFormElementsWrapper style={{ marginBottom: 13 }}>
                  <StyledLabel>
                    Have you sent the notice of arbitration? If yes, upload the
                    file. If no, download the template, send it to the
                    respondent and upload the file
                  </StyledLabel>
                  <CustomFileUpload
                    error={
                      touched?.arbitrationNotice && errors?.arbitrationNotice
                    }
                    setFile={(file) =>
                      s3Upload(
                        file,
                        `arbitrationNotice`,
                        setFieldValue,
                        setFieldError,
                      )
                    }
                    style={{
                      marginTop: 10,
                      width: "100%",
                    }}
                    accept=".pdf, .mp3, .mp4, .docx, image/*"
                    file={{ name: getFileName(values?.arbitrationNotice) }}
                  />
                  <div className="template">
                    <HyperLink
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      onClick={() =>
                        window.open(
                          "https://storage.googleapis.com/iiac-cloud-hosting-bucket-01/1/1724823588172-362e8b0a-b751-4bc1-8fba-353627953666/Notice%20of%20Arbitration.docx?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=iiac-bucket-service-account%40iiac-cloud-hosting-services.iam.gserviceaccount.com%2F20240828%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240828T053948Z&X-Goog-Expires=604800&X-Goog-SignedHeaders=host&X-Goog-Signature=177cc94a984bbce4eed27a0969e87e82e8511ee481370c9af935f77162dc3ffb4cecf3bd585dbac330784c2df32b45efa281c5a18103cc1937990991add28115b43faf912200b822712cf11f57d9e04812f8aaa239c0691c53571c094b5e06447d70693ea920233aab1f718a95fb8989e95607d39a30c030fcaeaada7cf4c9ff5b0d7d8e39af05dfea12f2f4f60295548978a7008131217cf36bb9f78f5ae25bb0b859707045216264659be13ca5150bd4041ffcc81f2eb92221651d725b87300c8cf77e39af0ebc54423a65dd8f127e0a7760deafeb96a6a229b2a6ae458effb68ff9252a9034bcca209edae035847c39071b2be4c94e1ca0bbd9ab3742a283",
                          "_blank",
                        )
                      }
                    >
                      Please download the template.
                    </HyperLink>
                  </div>
                </StyledFormElementsWrapper>
              )}

              {/* drop down select claimant party */}
              <PartySelect
                {...{
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  menuItems,
                }}
              />

              {/* Case description box */}
              {inputs.map((input, key) => (
                <StyledFormElementsWrapper
                  style={{ marginBottom: 15 }}
                  key={key}
                >
                  <FormField
                    white
                    {...{
                      input,
                      key,
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </StyledFormElementsWrapper>
              ))}

              {/* claimvalue */}
              {values?.resolutionKind === "arbitration" ? (
                <StyledFormElementsWrapper style={{ maxWidth: "236px" }}>
                  <FormField
                    white
                    input={{
                      type: "select",
                      name: "currencyUnit",
                      label: "Currency Unit",
                      required: true,
                    }}
                    menuItems={currencyType}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </StyledFormElementsWrapper>
              ) : null}
              {values?.resolutionKind === "arbitration" ? (
                <TotalClaimValue
                  ressolutionKind={values.resolutionKind}
                  values={values.totalClaimValue}
                  currencyType={values.currencyUnit}
                  handleChange={handleChange}
                  name={"totalClaimValue"}
                  {...{ errors }}
                  onBlur={(_) => {
                    setFieldTouched(`totalClaimValue`, true);
                  }}
                />
              ) : (
                ""
              )}

              {/* note */}

              {/* multiple upload */}
              <MultipleUpload
                values={values}
                setFieldValue={setFieldValue}
                getFileName={getFileName}
                setFieldError={setFieldError}
                handleDeleteAgreement={handleDeleteAgreement}
                touched={touched}
                errors={errors}
                menuItems={caseDocumentType}
                handleBlur={handleBlur}
                {...{ s3Upload }}
              />

              {/* Additional Mediator for arbitration */}
              <AdditionalMediator
                {...{
                  values,
                  setFieldValue,
                  setFieldError,
                  touched,
                  errors,
                  handleBlur,
                }}
              />
              {/* Additional Mediator for arbitration */}

              {/* buttons */}
              <CaseActionCTA
                editFlag={caseObj?.editFlag}
                isBigScreen={isBigScreen}
                onClick={() =>
                  caseObj?.editFlag
                    ? navigate(`/dashboard/cases?caseType=arbitration`)
                    : onFormSubmit(values, null, true)
                }
                handleSubmit={handleSubmit}
              />
            </StyledForm>
          )}
        </Formik>
      </CreateCaseContainer>
      <AlertDialog isOpen={open} {...{ ...dialogData }} />
    </Container>
  );
};

export default Index;
