import React, { useEffect } from "react";
import { Header } from "../../internal/Header/Header";
import { H2, P, AboutUsBody, GreySection } from "./Styles";
import labels from "../../../helpers/labels.json";
import Footer from "../LandingPage/Footer";
import { scroller } from "react-scroll";
import { HyperLink } from "../../common/FormInputs";

const AboutUs = (props) => {
  const section = props.location.hash.slice(1);
  useEffect(() => {
    scroller.scrollTo(section, {
      duration: 500,
      offset: -70,
      smooth: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  return (
    <>
      <div>
        <Header>
          <AboutUsBody>
            <GreySection id="about-iiac">
              <H2 style={{ textAlign: "center" }}>
                {labels["landing.what_is_justact"]}
              </H2>
              <P
                style={{ textAlign: "justify" }}
                // dangerouslySetInnerHTML={{
                //   __html: labels["landing.what_content"]
                // }}
              >
                IIAC is an{" "}
                <HyperLink
                  onClick={() => window.open(`https://user.indiaiac.org/`)}
                >
                  Online Dispute Resolution
                </HyperLink>{" "}
                platform offered by Lex Carta Private Limited and is set up by
                lawyers and professionals to disrupt the way disputes are
                resolved today.
                <br />
                <br />
                We believe that long amounts of time spent in the courts is
                detrimental to the economy and IIAC will improve the
                productivity of society manifold.
                <br />
                <br />
                Our mission is to transform dispute resolution by bringing
                together mediators, arbitrators and litigants using technology
                to provide cost effective solution and to create an ecosystem &
                culture where people are pre-disposed to settling disputes
                amicably with fairness and equity.
              </P>
            </GreySection>
          </AboutUsBody>

          <Footer />
        </Header>
      </div>
    </>
  );
};
export default AboutUs;
