import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { navigate } from "@reach/router";
import { H2 } from "./Styles";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiButton-root": {
      minWidth: "auto",
    },
    [theme.breakpoints.up(640)]: {
      "&.MuiButton-root": {
        minWidth: "64px",
      },
    },
  },
  list: {
    overflow: "auto",
  },
  fullList: {
    width: "unset",
  },
  padder: {
    paddingTop: "74px",
  },
  sidePadder: {
    paddingLeft: "2%",
    paddingRight: "5%",
    margin: 0,
    boxShadow: "none",
    borderBottom: "1px solid #e8e8e8",
  },
  listPadder: {
    padding: 0,
  },
}));

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => {
    setState(open);
  };

  const list = () => (
    <div
      className={clsx(classes.list, classes.fullList, classes.padder)}
      role="presentation"
      //   onClick={toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <ExpansionPanel className={classes.sidePadder}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <H2>Resources</H2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                navigate("/resources/blogs");
              }}
            >
              Blogs
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                navigate("/resources/webinar-videos");
              }}
            >
              Webinars
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                navigate("/resources/model-clauses");
              }}
            >
              Model Clauses
            </MenuItem>
          </MenuList>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.sidePadder}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <H2>Neutrals</H2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                navigate("/neutral/arbitrators");
              }}
            >
              Arbitrators
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                window.open(process.env.REACT_APP_NEUTRAL_BASE_URL, "_blank");
              }}
            >
              Login as Neutral
            </MenuItem>
          </MenuList>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.sidePadder}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <H2>Training</H2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                navigate("/training/corporate-short-programme");
                toggleDrawer(false);
              }}
            >
              Corporate Short Training
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/training/mediation-training-programme");
                toggleDrawer(false);
              }}
            >
              Mediator Training Programme
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                navigate("/training/world-of-mediation");
                toggleDrawer(false);
              }}
            >
              World of Mediation
            </MenuItem> */}
          </MenuList>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.sidePadder}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <H2>About Us</H2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleDrawer(false);
                navigate("/regulations");
              }}
            >
              Regulations
            </MenuItem>
          </MenuList>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );

  return (
    <div style={{ display: "flex" }}>
      <Container>
        <LoginDiv
          onClick={() => {
            navigate("/login");
            toggleDrawer(true);
          }}
        >
          <p> Login</p>
        </LoginDiv>
        <LoginDiv
          onClick={() => {
            navigate("/signup");
            toggleDrawer(true);
          }}
        >
          <p> Sign Up</p>
        </LoginDiv>
      </Container>
      {
        <React.Fragment key="top">
          <Button
            className={classes.root}
            onClick={() => (state ? toggleDrawer(false) : toggleDrawer(true))}
          >
            <MenuIcon />
          </Button>
          <Drawer
            anchor={"top"}
            open={state}
            onClose={() => toggleDrawer(false)}
            style={{ zIndex: "1" }}
          >
            {list()}
          </Drawer>
        </React.Fragment>
      }
    </div>
  );
}

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MenuItem = styled.div`
  display: flex;
  margin: 5px 0;
  padding: 10px;
  height: 50px;
  width: 100%;
  font-family: ${theme.fonts.primaryFontRegular};
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  margin: auto 0px;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LoginDiv = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  margin-right: 10px;
  &:first-child {
    margin-right: 15px;
  }
  cursor: pointer;
  transition: 350ms;
  &:hover {
    color: ${COLORS.BTN_GREEN};
  }
  @media (min-width: 500px) {
    font-size: 16px;
    margin-right: 20px;
    &:first-child {
      margin-right: 35px;
    }
  }
`;
