import React, { useState, useEffect, useRef } from "react";
import {
  PageWrapper,
  Heading,
  TableWrapper,
  Footer,
  Hr,
  Row,
  HyperLink,
  StyledTitle,
  TextWrapper,
  Text,
  TextBold,
} from "../CaseCart/Styles";
import { PrimaryCTAButton } from "../../common/Buttons";
import CustomTable from "../../common/CustomTable/CustomTable";
import { Header } from "../Header/Header";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import {
  getErrorMessage,
  parseTimeStamp,
  renderStatus,
} from "../../../helpers/functions";
import moment from "moment";
import { navigate } from "@reach/router";
import { PAYMENT_STATUS_CART } from "../../../helpers/constants";
import labels from "../../../helpers/labels.json";
import { CartSplitUp } from "../CartSplitUp";
import CartService from "../../../services/CartService";
import useLoader from "../../../hooks/useLoader";

async function getCases(query = "") {
  try {
    const response = await CartService.getSelectedCartItem(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const ProfomaCaseListing = ({ location }) => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();

  useEffect(() => {
    if (state?.message) {
      enqueueSnackbar(state.message, {
        variant: "error",
      });
      navigate("/dashboard");
    }
    if (!location?.state?.selectedCartAmount) {
      navigate("/dashboard/cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, location]);

  const columns = [
    {
      field: "id",
      title: labels["table.case_id"],
      tooltip: "Unique Identifier for the Case across IIAC platform",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData?.case?.id}?caseType=${rowData?.case?.resolutionKind}`
            )
          }
        >
          {rowData?.case?.id}
        </HyperLink>
      ),
    },
    {
      field: "title",
      title: labels["table.case_title"],
      sorting: false,
      render: (rowData) => (
        <StyledTitle
          onClick={() => navigate(`/dashboard/cases/${rowData?.case?.id}`)}
        >
          {rowData?.case?.title}
        </StyledTitle>
      ),
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: (rowData) => "INR " + Number(rowData?.fee?.total).toFixed(2),
    },
    {
      field: "created_at",
      title: labels["table.due"],
      sorting: true,
      render: (rowData) =>
        rowData?.case?.submittedOn
          ? moment(rowData?.case?.submittedOn)
              .add(7, "days")
              .format("DD/MM/YYYY")
          : moment(parseTimeStamp(rowData?.case?.created_at))
              .add(7, "days")
              .format("DD/MM/YYYY"),
    },
    {
      field: "details",
      title: labels["table.description"],
      sorting: false,
      render: (rowData) =>
        renderStatus(
          PAYMENT_STATUS_CART[rowData?.case?.status]?.value,
          rowData?.case
        ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        inCart: true,
        pagination: true,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }

      let stringParams = `?${queryString.stringify(params)}`;
      getCases(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result?.cartItems.data,
              page: result?.cartItems.page - 1,
              total: result?.cartItems.total,
            });
            setState(result?.cartItems);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  const detailPanel = [
    {
      tooltip: "View detailed split up...",
      render: (rowData) => {
        return <CartSplitUp data={rowData} />;
      },
    },
  ];

  const generateProfoma = async () => {
    try {
      setLoader({ state: true, message: "Generating Proforma Invoice..." });
      const result = await CartService.generatePerforma();
      if (result?.status === "proformaGenerated") {
        enqueueSnackbar("Profoma invoice is generated Successfully", {
          variant: "success",
        });
        navigate("/dashboard/cart");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <Header showSidebar>
      <PageWrapper>
        <Heading>
          <span onClick={() => navigate("/dashboard/cart")}>
            {labels["appbar.case_cart"]}
          </span>
          <span>{" > "}</span>
          <span>{labels["selected_case"]}</span>
        </Heading>
        <TableWrapper>
          <CustomTable
            hidePagination={state?.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            {...{
              columns,
              data,
              MTRef,
              state,
            }}
            detailPanel={detailPanel}
            singularTitle=""
            pluralTitle={labels.cases}
            noToolbar
          />
        </TableWrapper>
        <Footer>
          <Hr />
          <Row style={{ marginTop: 30, marginBottom: 30 }}>
            <div>
              <TextWrapper>
                <Text>{labels.payable_amount}: </Text>
                <TextBold>
                  INR {location?.state?.selectedCartAmount || 0}
                </TextBold>
              </TextWrapper>
              <div className="highlighted">*All line items include 18% GST</div>
            </div>
            <PrimaryCTAButton
              style={{
                width: "216px",
                marginLeft: "26px",
              }}
              onClick={() => generateProfoma()}
            >
              {labels.proceed}
            </PrimaryCTAButton>
          </Row>
        </Footer>
      </PageWrapper>
    </Header>
  );
};

export default ProfomaCaseListing;
