import React from "react";
import { Formik } from "formik";
import { Header } from "../../internal/Header/Header";
import { Center, Heading } from "../../../styles/component/style";
import styled from "styled-components";
import { PrimaryCTAButton } from "../../common/Buttons";
import Rating from "@material-ui/lab/Rating";
import { CustomInputField } from "../../common/FormInputs";
import useLoader from "../../../hooks/useLoader";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate } from "@reach/router";

const Index = ({ id }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const onFormSubmit = async (values) => {
    setLoader({ state: true, message: "Loading..." });
    const postData = { ...values };
    postData.value = Number(postData.value);
    try {
      const res = await CaseService.caseRating(id, postData);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      navigate("/dashboard/cases");
    }
  };

  return (
    <Header>
      <Center>
        <Card>
          <Align>
            <Heading style={{ textTransform: "unset", marginBottom: "54px" }}>
              How was your experience with IIAC?
            </Heading>
            <Formik
              initialValues={{
                comment: "",
                value: 4.5,
              }}
              onSubmit={onFormSubmit}
            >
              {({ handleChange, handleSubmit, values }) => (
                <form>
                  <StarsWrapper>
                    <Rating
                      name="value"
                      value={values.value}
                      precision={0.5}
                      size="large"
                      onChange={handleChange}
                    />
                  </StarsWrapper>
                  <div class="review-title">Write Feedback</div>
                  <CustomInputField
                    variant="outlined"
                    className={"input-height-auto"}
                    style={{ marginTop: 6 }}
                    multiline={true}
                    rows={5}
                    maxRows={5}
                    name={`comment`}
                    onChange={handleChange}
                  />
                  <ButtonWrapper>
                    <PrimaryCTAButton
                      onClick={handleSubmit}
                      style={{
                        width: "226px",
                        height: "32px",
                        padding: "unset",
                        marginLeft: "unset",
                      }}
                    >
                      Submit Feedback
                    </PrimaryCTAButton>
                  </ButtonWrapper>
                </form>
              )}
            </Formik>
          </Align>
        </Card>
      </Center>
    </Header>
  );
};

export default Index;

const Card = styled.div`
  width: 944px;
  height: 529px;
  border-radius: 12px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  & .review-title {
    font-size: 10px;
    font-weight: 600;
    line-height: 1.7;
    color: #949ab0;
    text-transform: uppercase;
  }
  & .MuiRating-decimal {
    margin-left: 5px;
  }
`;
const StarsWrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;
const Align = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: center;
`;
